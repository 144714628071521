/* reset */
$min-width: 280px; 
$max-width: 4000px; 
$base-bg: #F5F5F7; 
$base-font-size: 16px; 
$base-line-height: 28px; 
$base-font-family: Arial, sans-serif; 
$custom-font-family: 'Rubik', sans-serif;
$link-color: #fff;
$default-transition:all 0.3s linear;

/* main variables */

//text, titles
$text-color: #8B8EAC;
$title-color: #292D66;;
$title-font-family: $custom-font-family;

$font-size-h1: 30px;
$line-height-h1: 36px;
$font-size-h2: 30px;
$line-height-h2: 36px;
$font-size-h3: 26px;
$line-height-h3: 32px;