@font-face {
     font-family: 'FuturaPT';
     src: url('../fonts/FuturaPT/FuturaPT-Book.eot');
     src: url('../fonts/FuturaPT/FuturaPT-Book.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT/FuturaPT-Book.woff') format('woff'), url('../fonts/FuturaPT/FuturaPT-Book.ttf') format('truetype');
     font-weight: 400;
     font-style: normal;
}
 @font-face {
     font-family: 'FuturaPT';
     src: url('../fonts/FuturaPT/FuturaPT-Medium.eot');
     src: url('../fonts/FuturaPT/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT/FuturaPT-Medium.woff') format('woff'), url('../fonts/FuturaPT/FuturaPT-Medium.ttf') format('truetype');
     font-weight: 500;
     font-style: normal;
}
 @font-face {
     font-family: 'FuturaPT';
     src: url('../fonts/FuturaPT/FuturaPT-Demi.eot');
     src: url('../fonts/FuturaPT/FuturaPT-Demi.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT/FuturaPT-Demi.woff') format('woff'), url('../fonts/FuturaPT/FuturaPT-Demi.ttf') format('truetype');
     font-weight: 600;
     font-style: normal;
}
 @font-face {
     font-family: 'FuturaPT';
     src: url('../fonts/FuturaPT/FuturaPT-Bold.eot');
     src: url('../fonts/FuturaPT/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT/FuturaPT-Bold.woff') format('woff'), url('../fonts/FuturaPT/FuturaPT-Bold.ttf') format('truetype');
     font-weight: 700;
     font-style: normal;
}
/* ================================================================================ | HEADER | ================================================================================ */
 * {
     box-sizing: border-box;
}
 body {
     scroll-behavior: smooth;
}
 .wrapper {
     min-width: 280px;
     padding: 0;
     overflow: hidden;
     margin: 0 auto;
     font-family: 'FuturaPT' ,Arial, Helvetica,sans-serif;
     line-height: 120%;
     background: #fff;
}
 .wrapper::before {
     content: "";
     background-color: rgba(0, 0, 0, .2);
     position: fixed;
     width: 100%;
     height: 100%;
     top: 0;
     left: 0;
     opacity: 0;
     transition: opacity .8s ease 0s;
    /* pointer-events: none;
    */
     display: block;
     z-index: -1;
}
 .popup-show .wrapper::before {
     opacity: 1;
     z-index: 100;
}
 .popup-show body{
     overflow: hidden;
/*     padding-right: 8px;*/
}
 @media screen and (min-width: 1024px) {
 .popup-show body{
     padding-right: 8px;
  }
 }
 .popup-show body::after {
     opacity: 1;
}
 html {
     font-size: 16px;
}
 @media screen and (min-width: 768px) {
     html {
         font-size: 0.833vw;
    }
}
 strong {
     font-weight: 700;
}
 a {
     text-decoration: underline;
     transition: all 0.3s ease;
}
 a:hover {
     text-decoration: none;
}
 p{
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 400;
     line-height: 140%;
     margin: 0 0 1rem 0;
}
 h2{
     color: rgb(2, 46, 56);
     font-size: 28px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0.16px;
     margin: 0 0 16px 0;
}
 h3{
     color: rgb(2, 46, 56);
     font-size: 24px;
     font-weight: 450;
     line-height: 120%;
     letter-spacing: 0.16px;
     margin: 0 0 16px 0;
}
@media screen and (min-width: 1024px){
   p{
     font-size: 18px;
  }
}
 .container_full {
     padding: 0 0.8rem 0 0.8rem;
     max-width: 100%;
     margin: 0 auto;
}
 .row {
     margin-right: -0.8rem;
     margin-left: -0.8rem;
}
 .row:after, .row:before {
     content: '';
     width: 100%;
     display: table;
     height: 0;
     clear: both;
}
 .clear:after, .clear:before {
     content: '';
     width: 100%;
     display: table;
     height: 0;
     clear: both;
}
 .row_flex {
     display: -ms-flexbox;
     display: flex;
     -ms-flex-direction: row;
     flex-direction: row;
     -ms-flex-wrap: wrap;
     flex-wrap: wrap;
     -ms-flex-pack: normal;
     justify-content: normal;
     margin-right: -0.625rem;
     margin-left: -0.625rem;
}
 .column100{
     padding-left: 0.8rem;
     padding-right: 0.8rem;
}
 .container {
     max-width: 100%;
     margin: 0 auto;
     padding: 0 1rem 0 1rem;
}
 @media screen and (min-width: 1380px) {
     .container {
         width: 1328px;
         padding: 0 0rem 0 0rem;
    }
}
 .marquee{
     margin: 0px auto 0 auto;
     padding: 6px 0;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-content: center;
     align-content: center;
     background-position: center;
     background-size: cover;
     background-repeat: no-repeat;
     background-image: url(../img/marquee.jpg);
     width: 1584px;
     min-height: 54px;
     max-width: 100%;
}
@media screen and (min-width: 1024px){
   .marquee{
     margin: 8px auto 0 auto;
     padding: 16px 0;
   }
}
 .marquee_ins{
     color: rgb(2, 46, 56);
     font-size: 13px;
     font-weight: 500;
     line-height: 17px;
     letter-spacing: 1.92px;
     text-align: center;
     text-transform: uppercase;
     padding: 0 16px;
}
@media screen and (min-width: 1024px){
   .marquee_ins{
     line-height: 21px;
     font-size: 16px;
    padding: 0 20px;
   }
}
 .top_header-fl{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: center;
     align-items: center;
     -webkit-align-content: stretch;
     align-content: stretch;
     border-bottom: 1px solid rgb(230, 230, 230);
}

 .head_l, .head_r{
     width: 45%;
}
 .head_c{
     padding: 5px 10px 5px 10px;
}
@media screen and (min-width: 1024px){
   .head_c{
     padding: 12px 10px 12px 10px;
  }
}
 .menu__head-wrapper{
     padding: 0;
     box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.02);
     margin-bottom: 12px;
     position: relative;
     display: block;
}
 @media screen and (max-width: 1023px){
  .menu__head-wrapper{
      padding: 0 0px;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.02);
      position: fixed;
      left: -300%;
      width: auto;
      z-index: 120;
      background: #fff;
      width: 304px;
      top: 0;
      height: 0;
      transition: all 0.3s linear;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: space-between;
      justify-content: space-between;
  }
  .on .menu__head-wrapper {
      padding: 0 16px;
      left: 0;
      z-index: 120;
      height: 100vh;
  }
  .menu__head-wrapper
  .container{
    padding: 0;
    margin: 0;
  }
  .top__header
  .desk_hidden{
    display: none;
  }
  .mobile_top{
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    padding: 20px 40px 17px 0;
  }
  .menu__head-wrapper
  .close_popup {
    top: 24px;
    right: 16px;
    background: rgb(247, 249, 250);
  }


}
 .menu__head {
   padding: 0px 0px;
   margin: 0 0 24px 0;
}
 @media screen and (min-width: 1024px){
   .menu__head {
     border-top: 1px solid rgb(223, 233, 236);
     padding: 28px 0px;
     margin: 0 auto 0 auto;
     list-style:none;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-content: center;
     align-content: center;
   }
 }
 .menu__head > li {
     display: block;
     margin: 0 0px 0 0;
     padding: 13px 0 13px 0;
     border-bottom: 1px solid #EFEFEF;
}
 .menu__head > li > a{
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 500;
     line-height: 19px;
     letter-spacing: 0.48px;
    /*3%*/
     text-align: center;
     text-transform: uppercase;
     text-decoration: none;
}
 .menu__head > li > a:hover{
     text-decoration: none;
     color: #839283;
}
 .menu__head > li .color_link{
     color: #7C183E;
}
 @media screen and (min-width: 1023px) and (max-width: 1100px){
     .menu__head > li > a{
         font-size: 13px;
    }
}
 @media screen and (min-width: 1200px) {
     .menu__head {
         padding: 28px 28px;
    }
}
 @media screen and (min-width: 1024px) {
    .menu__head > li {
         display: inline-block;
         vertical-align: top;
         margin: 0 0px 0 0;
         padding: 0;
         border: none;
    }
     .menu__head > li > a:hover{
         text-decoration: none;
         color: #839283;
         border-bottom: 1px solid #839283;
    }
  .toggle_block,
  .mobile-visib{
    display: none;
  }
}


 .toggle {
     width: 22px;
     padding: 0px 0px;
     z-index: 100;
     vertical-align: middle;
     cursor: pointer;
}
 .toggle span {
     width: 100%;
     height: 2px;
     margin: 4px 0px;
     background: rgb(17, 17, 17);
     transition: transform 0.3s linear;
     -webkit-backface-visibility: hidden;
     backface-visibility: hidden;
     display: block;
     border-radius: 0px;
}
 .on .toggle span:first-child, .toggle_close .toggle span:first-child {
     -ms-transform: rotate(45deg) translate(5px, 4px);
     transform: rotate(45deg) translate(5px, 4px);
}
 .on .toggle span:last-child , .toggle_close .toggle span:last-child {
     width: 100%;
     -ms-transform: rotate(-45deg) translate(4px, -4px);
     transform: rotate(-45deg) translate(4px, -4px);
}
 .on .toggle span:nth-child(2), .toggle_close .toggle span:nth-child(2) {
     opacity: 0;
}
 .menu__head li.drobd > ul{
     display: block;
}
 .drobdown-list{
     height: 0;
     overflow: hidden;
     min-height: 0;
     opacity: 0;
     margin: 0;
     border-bottom: 1px solid rgb(233, 233, 233);
}
 .open .drobdown-list{
     height: auto;
     overflow: visible;
     min-height: inherit;
     opacity: 1;
     margin: 0px 0;
     padding: 10px 0;
     border-bottom: 1px solid rgb(233, 233, 233);
}

 .menu__head li .drobdown-list > li:last-child{
     margin-bottom: 6px;
}
 .menu__head li .drobdown-list > li > a {
     padding: 6px 0;
     margin: 0px 0px 0px 12px;
}
 .drobdown__block{
    display: none;
    height: 0;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
    padding: 0px 1rem;
}
.openes .drobdown__block{
   height: auto;
   opacity: 1;
   overflow: visible;
   display: block;
}
.drobd_images{
  display: none;
}
.arrowmenu{
  position: absolute;
  right: 0;
  top: 13px;
  width: 24px;
  height: 24px;
  background-image: url(../img/arrow_dwn.svg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  cursor: pointer;
}

 @media screen and (min-width: 1024px) {
     .drobdown__block{
         position: absolute;
         left: 0;
         top: 100%;
         margin-top: -1px;
         width: 100%;
         background: #FFFFFF;
         box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.16);
         padding: 30px 1rem;
         border-top: 1px solid rgba(0, 0, 0, 0.16);
    }
    .arrowmenu{
      display: none;
    }
    .drobd{
      padding-right: 0px;
    }
}
 @media screen and (max-width: 1023px) {
  .drobd{
    padding-right: 30px;
    position: relative;
  }
  .container__menu{
    overflow-y: hidden;
  }
  .mobile-menu{
    height: calc( 100% - 70px );
    overflow-y: auto;
  }
}

 @media screen and (min-width: 1300px) {
     .drobdown__block{
         padding: 30px 100px ;
    }
}
 @media screen and (min-width: 1024px) {
    .openes .drobdown__block {
         z-index: 50;
         box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.02);
    }
     .drobdown__container{
         display: -ms-flexbox;
         display: -webkit-flex;
         display: flex;
         -webkit-flex-direction: row;
         -ms-flex-direction: row;
         flex-direction: row;
         -webkit-flex-wrap: nowrap;
         -ms-flex-wrap: nowrap;
         flex-wrap: nowrap;
         -webkit-justify-content: space-between;
         -ms-flex-pack: justify;
         justify-content: space-between;
         -webkit-align-items: stretch;
         -ms-flex-align: stretch;
         align-items: stretch;
    }
     .drobdown_list{
         -webkit-flex: 1 1 auto;
         -ms-flex: 1 1 auto;
         flex: 1 1 auto;
         text-align: left;
         column-count: 3;
         column-gap: 1em;
    }
     .drobd_images{
         padding: 0 0;
         width: 25%;
         display: block;
    }
     .cat_img{
         width: 100%;
         height: 260px;
         background-repeat: no-repeat;
         background-size: cover;
         background-position: center;
    }
     .drobdown_list a{
       text-transform: uppercase;
     }
  }
 @media screen and (min-width: 1300px) {
     .drobd_images{
         padding: 0 0 0 16px;
    }
     .cat_img{
         width: 318px;
         height: 380px;
    }
}
 .drobdown_list a{
     color: #022E38;
     font-size: 15px;
     font-weight: 500;
     line-height: 120%;
     text-transform: none;
     text-decoration: none;
     display: block;
     padding: 3px 0;
     margin: 10px 0px 10px 0px;
}
 .drobdown_list a:hover{
     text-decoration: none;
     color: #B28C6A;
}
 @media screen and (min-width: 1024px) {
     .drobdown_list a{
       text-transform: uppercase;
     }
  }
 .arrow_back{
     color: rgb(2, 46, 56);
     font-size: 12px;
     font-weight: 500;
     line-height: 18px;
     letter-spacing: 0.32px;
     text-transform: uppercase;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 12px;
     text-decoration: none;
     padding: 0px 0 0 0;
}
 @media screen and (min-width: 768px) {
   .arrow_back{
       font-size: 14px;
  }
 }
  @media screen and (min-width: 1024px) {
   .arrow_back{
       padding: 16px 0 0 0;
  }
 }
 .arrow_back:hover{
     opacity: 0.8;
}
 .contact-icon_block{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
}

 .logo_head {
     text-decoration: none;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     margin: 0;
     height: 40px;
     max-height: 40px;
}
 .logo_head:hover {
     text-decoration: none;
}
.logo_head svg {
     height: auto;
     max-width: 100%;
     max-height: 100%;
     display: block;
}
.logo_head img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}
 @media screen and (min-width: 1024px) {
   .logo_head {
      height: inherit;
     max-height: 78px;
   }
 }
 .head_r{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-end;
     justify-content: flex-end;
     -webkit-align-content: stretch;
     align-content: stretch;
     -webkit-align-items: center;
     align-items: center;
}
 .leng__elem{
     position: relative;
}
  .leng__title{
    color: rgb(128, 139, 142);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 2px;
  }
  .mobile_top
  .leng__block-ch{
    margin: 0;
  }
  .mobile_top
  .leng__elem {
    margin-right: 32px;
  }
  .mobile_top
  .leng__bl{
    left: 0;
  }
 .leng__bl{
     position: absolute;
     top: calc( 100% + 10px);
     left: 6px;
     width: auto;
    /* transform: translateX(-50%);
    */
     padding: 10px 10px 2px 10px;
     text-align: center;
     background: #fff;
     height: 0;
     opacity: 0;
     box-shadow: 0px 0px 30px rgba(5, 7, 0, 0.1);
}
 .leng__block-ch{
     position: relative;
     padding-right: 16px;
     margin-left: 12px;
     margin-right: 12px;
     cursor: pointer;
}
 .leng__bl .leng__bl-ch{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     margin: 0 0 10px 0;
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 19px;
     letter-spacing: 0%;
     text-align: center;
     text-transform: uppercase;
     cursor: pointer;
}
 .leng__bl .leng__bl-ch span, .leng__bl .leng__bl-ch svg{
     display: inline-block;
     vertical-align: middle;
     margin: 0 4px;
}
 .leng__bl .leng__bl-ch{
     margin: 0 0 10px 0;
}
 .leng__icon{
     position: absolute;
     right: 2px;
     top: 1px;
}
 .leng__text{
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 19px;
     display: inline-block;
     vertical-align: middle;
}
 .op_l .leng__bl{
     height: auto;
     opacity: 1;
     z-index: 2;
}
 .icon_top{
     padding: 8px 12px;
     cursor: pointer;
}
 @media screen and (min-width: 1024px) {
   .icon_top{
     padding: 12px 13px;
     cursor: pointer;
   }
 }
 .contact-tel{
     color: rgb(2, 46, 56);
     font-size: 12px;
     font-weight: 400;
     line-height: 23px;
     text-decoration: none;
     text-transform: uppercase;
}
 @media screen and (min-width: 768px) {
 .contact-tel{
     font-size: 18px;
  }
 }
 .contact-tel:hover{
     text-decoration: none;
     opacity: 0.8;
}
 .contact-icon{
     width: 40px;
     height: 40px;
     border-radius: 50%;
     background: rgb(246, 246, 246);
     margin-right: 8px;
     padding: 10px;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
}
@media screen and (max-width: 767px) {
  .order_contact-head
  .contact-icon{
    display: none;
  }
}

 .modal_l_block, .modal_r_block{
     overflow: auto;
     position: fixed;
     top: 0;
     background: #fff;
     padding: 0;
     z-index: 10;
     min-width: 280px;
     max-width: 100%;
     transition: all 0.2s linear;
     box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
     z-index: -1;
     opacity: 0;
     height: 0;
}


@media screen and (min-width: 560px) {
   .modal_l_block, .modal_r_block{
     min-width: 400px;
  }
   .modal_r_block{
       min-width: 520px;
  }
   #basket_mod{
     max-width: 540px;
  }
}
 .open.modal_r_block, .open.modal_l_block{
     opacity: 1;
     z-index: 1000;
     height: 100vh;
}
 .modal_l_block{
     left: -1500px;
}
 .open.modal_l_block{
     left: 0;
}
 .modal_r_block{
     right: -1500px;
}
 .open.modal_r_block{
     right: 0;
}
 .modal_t_block{
     overflow: auto;
     position: fixed;
     top: -2000px;
/*     background: #fff;*/
     padding: 0;
     z-index: 10;
     max-width: 100%;
     transition: all 0.2s linear;
     box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
     z-index: -1;
     opacity: 0;
     height: 0;
     left: 50%;
     transform: translateX(-50%);
     width: 100%;
     padding-left: 16px;
     padding-right: 16px;
}

 .open.modal_t_block{
     opacity: 1;
     z-index: 1000;
     height: auto;
     max-height: 100vh;
     top: 32px;
}
 #modalsize{
     width: 760px;
     max-width: 100%;
}
 .modal_regist{
     background: #fff;
     padding: 0;
     z-index: 10;
     width: 560px;
     max-width: 100%;
     transition: all 0.3s linear;
     box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
    /* display: none;
    */
}

 .modal_fl{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
}
 .modal_top{
     -webkit-flex: 1 0 auto;
     flex: 1 0 auto;
}
 .modal_r-top{
     position: relative;
     padding: 24px 16px;
     background: rgb(247, 249, 250);
}
@media screen and (min-width: 768px) {
  .modal_r-top{
     padding: 24px;
  }
}
 .modal_r_block .modal_r-top{
     background: transparent;
}
 .modal_reg{
     position: relative;
     text-align: center;
     padding: 20px 16px 20px 16px;
     margin: 0 0 0px 0;
}
 @media screen and (min-width: 768px){
   .modal_reg{
     padding: 40px 50px 40px 50px;
  }
 }
 .modal_r-bottom{
     padding: 0px 16px;
     position: relative;
}
@media screen and (min-width: 768px) {
   .modal_r-bottom{
     padding: 0px 32px;
  }
}
 .modal_center-bottom{
     padding: 0px 16px;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     min-height: calc(100vh - 50px);
}
@media screen and (min-width: 768px) {
    .modal_center-bottom{
     padding: 0px 32px;
  }
}
 .close_popup{
     position: absolute;
     right: 24px;
     top: 24px;
     cursor: pointer;
}
 .menu__head-wrapper .close_popup,
 .modal_r_block .close_popup{
     background: rgb(247, 249, 250);
}
 .modal_r-btn{
     color: rgb(2, 46, 56);
     font-size: 20px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-transform: uppercase;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     gap: 6px;
}
 @media screen and (min-width: 768px){
   .modal_r-btn{
     font-size: 24px;
   }
 }
 .modal_r-btn span{
     padding-left: 12px;
     color: #C0C0C0;
}
 .modal_title{
     color: rgb(2, 46, 56);
     font-size: 20px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-transform: uppercase;
     margin: 0 0 12px 0;
}
 @media screen and (min-width: 768px){
     .modal_title{
         font-size: 28px;
    }
}
 .modal_text{
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 140%;
     margin: 0 0 12px 0;
}
 .block_reg-social{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     margin: 0 0 20px 0;
     column-gap: 12px;
}
 .block_reg-soc-el{
     background: rgb(248, 248, 248);
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 8px;
     padding: 9px 12px;
     border-radius: 60px;
     width: auto;
     color: rgb(2, 46, 56);
     font-size: 12px;
     font-weight: 450;
     line-height: 140%;
     text-transform: uppercase;
     cursor: pointer;
}
 @media screen and (min-width: 460px){
   .form_registr-elems{
       display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: row;
       flex-direction: row;
       -webkit-flex-wrap: wrap;
       flex-wrap: wrap;
       -webkit-justify-content: space-between;
       justify-content: space-between;
       -webkit-align-items: center;
       align-items: center;
  }

   .form_registr-elems .input_wr{
       width: calc(50% - 8px);
  }
}
 .modal_img{
     margin: 0 auto 12px auto;
}
 .modal_btns{
/*     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: center;
     align-items: center;
     padding: 10px 32px;
     column-gap: 8px;*/
     border-top: 1px solid rgb(239, 239, 239);
     padding: 10px 16px;
}
 .modal_btns-wrap{
     border-top: 1px solid rgb(239, 239, 239);
     padding-top: 16px;
     padding: 16px 0px 0px 0px;
}

@media screen and (max-width: 559px) {
  .modal_btns .btn_f{
    width: 100%;
  }
  .modal_btns .btn_f:first-child{
    margin-bottom: 8px;
  }
}
@media screen and (min-width: 560px) {
   .modal_btns{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: center;
     align-items: center;
     padding: 10px 32px;
     column-gap: 8px;
  }
}
@media screen and (min-width: 768px) {
   .modal_btns-wrap{
/*     padding: 16px 40px 0px 40px;*/
    padding: 16px 24px 0px 24px;
  }
}
 .modal_btns-wrap .modal_btns{
     border: none;
     padding: 20px 16px 24px 16px;
}
 .modal_btns-total{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 400;
     line-height: 23px;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: center;
     align-items: center;
     padding-left: 16px;
     padding-right: 16px;
}
 .totlel_summ{
     font-size: 20px;
     font-weight: 500;
     line-height: 26px;
}
 @media screen and (min-width: 768px) {
     .modal_btns{
         padding: 16px 32px;
         column-gap: 12px;
    }
}
 .btn_f{
     position: relative;
     display: inline-block;
     text-decoration: none;
     color: rgb(255, 255, 255);
     font-size: 14px;
     font-weight: 450;
     line-height: 24px;
     text-align: center;
     text-transform: uppercase;
     background: rgb(2, 46, 56);
     padding: 10px 10px;
     border-radius: 0;
     transition: all 0.3s linear;
     border: 1px solid rgb(2, 46, 56);
     flex: 1 0 auto;
}
 @media screen and (min-width: 768px) {
     .btn_f{
         width: 50%;
         padding: 10px 24px;
    }
}
 .btn_f-color1{
     background: rgb(2, 46, 56);
     border: 1px solid rgb(2, 46, 56);
     color: #fff;
     text-decoration: none;
}
 .btn_f-color2 {
     background: #fff;
     color: rgb(2, 46, 56);
     text-decoration: none;
}
 .btn_f-color1:hover {
     background: #b28c6a;
     border: 1px solid #b28c6a;
     text-decoration: none;
}
 .btn_f-color2:hover {
     background: rgb(17, 17, 17);
     color: #fff;
     text-decoration: none;
}
 .mod_img1{
     margin: 0 0 32px 0;
}
 .mod_img1 img, .mod_img1 img{
     width: auto;
     max-width: 100%;
}
 .search_block-wrapper{
     padding: 0 0 8px 0px;
}
 .search_modal-wrapper{
     padding: 11px 24px 0 24px;
     margin: 0 auto;
     width: 100%;
     background: #fff;
}

 .search_modal-top{
     position: relative;
}
 .search_modal-top .close_popup {

     right: 26px;
     top: -5px;
}
 .search_block{
     position: relative;
}
 .search_block svg{
     position: absolute;
     left: 0;
     top: 16px;
}
 .search_block input{
     border: none;
     border-bottom: 2px solid rgb(239, 239, 239);
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 400;
     line-height: 52px;
     height: 52px;
     padding: 0px 30px 0px 30px;
     background-color: #fff;
     width: 100%;
}
 .search_icon{
     position: absolute;
     left: 2px;
     top: 12px;
}
 .reset_search{
     width: 14px;
     height: 14px;
     position: absolute;
     right: 0;
     top: 14px;
     border: none;
     background-color: transparent;
     background-image: url(../img/search_reset.svg);
     background-repeat: no-repeat;
     background-position: center;
     background-size: auto;
}
 .search_result{
}
 .search_resalt-elems{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: stretch;
     align-items: stretch;
     column-gap: 16px;
}
 .search_resalt-el{
     width: 100%;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     border-bottom: 1px solid rgb(230, 230, 230);
     margin: 20px 0;
}
@media screen and (min-width: 560px) {
 .search_resalt-el{
    width: calc( 50% - 8px);
  }
}
@media screen and (min-width: 1024px) {
 .search_resalt-el{
     width: calc( 33.33% - 2.4%);
  }
   .search_resalt-elems{
     column-gap: 32px;
  }
}
@media screen and (min-width: 1200px) {
 .search_resalt-el{
     width: calc( 33.33% - 2%);
  }
 }
 @media screen and (min-width: 1360px) {
 .search_resalt-el{
     width: calc( 33.33% - 1.67%);
  }
 }
 .search_resalt-img{
     padding-right: 16px;
}
 .search_resalt-img img{
     width: 100px;
     height: 100px;
     -o-object-fit: contain;
     object-fit: contain;
     display: block;
}
 .search_resalt-title{
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 19px;
     margin-bottom: 8px;
}
 .search_resalt-body{
     text-align: right;
}
 .search_resalt-body .cart-price_block{
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
}
 .modal-res_btns{
     border-top: 1px solid rgb(239, 239, 239);
     margin-right: -24px;
     margin-left: -24px;
     padding: 16px 24px;
     text-align: right;
}
 .modal-res_btns .btn_f{
     display: inline-block;
     width: auto;
}
 .wish-elems{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: stretch;
     align-items: stretch;
}
  .wish-el{
       width: 100%;
       display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: row;
       flex-direction: row;
       -webkit-flex-wrap: nowrap;
       flex-wrap: nowrap;
       -webkit-justify-content: flex-start;
       justify-content: flex-start;
       -webkit-align-items: center;
       align-items: center;
       border: 1px solid rgb(239, 239, 239);
       margin: 0px 0;
       padding: 15px 12px;
       position: relative;
  }
 @media screen and (min-width: 460px) {
   .wish-el{
       width: 50%;
       padding: 8px 8px;
  }
}
 @media screen and (min-width: 1200px) {
   .wish-el{
       width: 33.33%;
       padding: 15px 12px;
  }
}
 .wish-el .icon_wish{
     top: 15px;
     right: 12px;
     background-image: url(../img/wish_f.svg);
}
 .wish-img{
     padding-right: 12px;
}
 .wish-img img{
     width: 50px;
     height: 50px;
     -o-object-fit: contain;
     object-fit: contain;
     display: block;
}
@media screen and (min-width: 460px) {
   .wish-img img{
     width: 60px;
     height: 60px;
 }
}
@media screen and (min-width: 768px) {
   .wish-img img{
     width: 70px;
     height: 70px;
 }
}
@media screen and (min-width: 1024px) {
   .wish-img img{
     width: 90px;
     height: 90px;
 }
}
 .wish-title{
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 19px;
     margin-bottom: 8px;
     text-align: left;
     padding-right: 20px;
}
 .wish-body{
     text-align: right;
}
 .wish-body .cart-price_block{
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
}
 .rewiew-el{
     width: 100%;
     border: 1px solid rgb(239, 239, 239);
     margin: 0px 0;
     padding: 0 16px 6px 16px;
     position: relative;
}
 @media screen and (min-width: 460px) {
   .rewiew-el{
       display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: row;
       flex-direction: row;
       -webkit-flex-wrap: nowrap;
       flex-wrap: nowrap;
       -webkit-justify-content: space-between;
       justify-content: space-between;
       -webkit-align-items: center;
       align-items: center;
       padding: 0;
  }
 }
 .rewiew-img{
     padding-right: 12px;
}
 .rewiew-img img{
     width: 100px;
     height: 100px;
     -o-object-fit: contain;
     object-fit: contain;
     display: block;
}
 .rewiew-title{
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 19px;
     margin-bottom: 8px;
}
 .rewiew-body{
     text-align: left;
     flex: 1 0 auto;
}
@media screen and (min-width: 768px) {
   .rewiew-body{
     text-align: left;
     flex: 1 0 auto;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-align-items: center;
      align-items: center;
      column-gap: 12px;
  }
}
 .btn-rew {
     position: relative;
     display: inline-block;
     text-decoration: none;
     color: #022e38;
     font-size: 14px;
     font-weight: 500;
     line-height: 20px;
     text-align: center;
     text-transform: uppercase;
     background: #fff;
     padding: 10px 32px;
     border-radius: 0;
     transition: all 0.3s linear;
     border: 1px solid #022e38;
     margin: 6px 0 6px 0;
}
 .btn-rew:hover {
     background: #022e38;
     color: #fff;
     text-decoration: none;
}
 .basket_title{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-transform: uppercase;
     margin-bottom: 20px;
}
 @media screen and (min-width: 768px) {
  .basket_title{
     font-size: 22px;
   }
 }
 .basket_title span{
     color: rgb(128, 139, 142);
     padding-left: 8px;
}
.order_list-elems{
     padding: 10px 10px 2px 10px;
     border: 1px solid rgb(239, 239, 239);
}
 @media screen and (min-width: 460px) {
   .order_list-elems{
       padding: 20px 16px 2px 16px;
  }
}
 @media screen and (min-width: 1024px) {
   .order_list-elems{
       padding: 24px 32px 2px 32px;
  }
}
 .basket_list-elems{
     padding: 32px 0px 0px 0px;
     margin-bottom: 40px;
}
 .order_list-el{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: stretch;
     align-items: stretch;
     position: relative;
}
 .order_list-el:last-child{
     border: none;
}
 .order_list-el{
     padding: 12px 0;
     position: relative;
     padding-right: 36px;
     border-bottom: 1px solid rgb(239, 239, 239);
}
 .order_list-el:last-child{
     border: none;
}
 .btn-del{
     position: absolute;
     right: 0;
     top: 12px;
     transform: translateY(0%);
}
 .order_list-bl .info-order-title{
     margin-bottom: 4px;
}
 .order_list-img{
     width: 50px;
     height: 50px;
     flex-shrink: 0;
}
 .basket_m-img img, .order_list-img img{
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     display: block;
}
 .basket_m-body{
     padding: 0px 40px 0px 16px;
     flex: 1 0 auto;
}
.order_list-body{
  padding: 0px 0px 0px 12px;
  flex: 1 0 auto;
}
@media screen and (min-width: 460px) {
   .btn-del{
     top: 50%;
     transform: translateY(-50%);
  }
   .order_list-img{
     width: 100px;
     height: 100px;
     flex-shrink: 0;
  }
   .order_list-body{
       display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: row;
       flex-direction: row;
       -webkit-flex-wrap: nowrap;
       flex-wrap: nowrap;
       -webkit-justify-content: space-between;
       justify-content: space-between;
       -webkit-align-items: center;
       align-items: center;
       flex: 1 0 auto;
  }
}
 @media screen and (min-width: 768px) {
}
 .order_list-price-block{
     text-align: right;
}
 .total_summ{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     color: rgb(17, 17, 17);
     font-size: 14px;
     font-weight: 600;
     line-height: 120%;
     column-gap: 12px;
}
 .total_summ div{
     margin: 0 0 20px 0;
}
 .btn_basket, .btn_small{
     position: relative;
     display: inline-block;
     text-decoration: none;
     font-size: 12px;
     font-weight: 600;
     text-align: center;
     text-transform: uppercase;
     padding: 10px 24px;
     border-radius: 0;
     transition: all 0.3s linear;
     height: 44px;
     line-height: 24px;
     color: #fff;
     background: #839283;
     border: 1px solid #839283;
     width: 100%;
}
 .btn_basket:hover, .btn_small:hover{
     text-decoration: none;
     color: #839283;
     background: #fff;
}
 .basket_elem{
     position: relative;
     cursor: pointer;
}
 .basket_elem span{
     position: absolute;
     right: -12px;
     top: -12px;
     color: rgb(255, 255, 255);
     font-size: 12px;
     font-weight: 400;
     line-height: 20px;
     text-align: center;
     text-transform: uppercase;
     height: 20px;
     min-width: 20px;
     border-radius: 50%;
     background: rgb(2, 46, 56);
}

 .swiper__wrapper{
     width: inherit;
     padding-right: 0px;
     margin: 0 -16px 0px -16px;
     height: 400px;
}
 @media screen and (min-width: 1024px){
     .swiper__wrapper{
        height: 460px;
        width: 100%;
        margin: 0 0 28px 0;
    }
}
 .swiper___pos {
    /* margin-right: -1060px;
     margin-left: -1060px;
    */
     height: 100%;
}
 .swiper___pos .swiperBanner{
     overflow: visible;
}
 .swiperBanner .swiper-slide{
     width: 1328px;
}
 .swiperBanner .swiper-pagination-bullet-active, 
 .swiperAcBl .swiper-pagination-bullet-active,
 .swiper_cart_wrap .swiper-pagination-bullet-active{
     background: rgb(2, 46, 56);
}
 .btn__main {
     position: relative;
     display: inline-block;
     text-decoration: none;
     color: rgb(255, 255, 255);
     font-size: 14px;
     font-weight: 500;
     letter-spacing: 0.32px;
     line-height: 20px;
     text-align: center;
     background: rgb(178, 140, 106);
     padding: 10px 32px;
     border-radius: 0;
     transition: all 0.3s linear;
     border: none;
}
 .btn__main:hover{
     background: #684d34;
     color: #fff;
     text-decoration: none;
}
 .btn__main:focus{
     background: #798879;
     color: #fff;
     text-decoration: none;
}
 .btn-main{
     position: relative;
     display: inline-block;
     text-decoration: none;
     color: white;
     font-size: 14px;
     font-weight: 450;
     line-height: 24px;
     text-align: center;
     text-transform: uppercase;
     background: #022e38;
     padding: 10px 32px;
     border-radius: 0;
     transition: all 0.3s linear;
     border: 1px solid #022e38;
     -ms-flex: 1 0 auto;
     flex: 1 0 auto;
}
 .btn-main:hover{
     background: #fff;
     color: #022e38;
     text-decoration: none;
}
 .btn-main:focus{
     background: #fff;
     color: #022e38;
     text-decoration: none;
}
 .breadcrumbs_container {
     margin-bottom: 16px;
     margin-top: 16px;
     overflow: auto;
     -ms-overflow-style: none;
     scrollbar-width: none;
}
 .breadcrumbs_container ::-webkit-scrollbar {
     width: 0;
     height: 0;
}
 @media screen and (min-width: 768px){
     .breadcrumbs_container {
        /* margin-bottom: 16px;
         */
         margin-bottom: 24px;
         margin-top: 32px;
    }
}
 .bredcrumb {
     display: inline-block;
     vertical-align: middle;
     white-space: nowrap;
}
 .bredcrumb a, .bredcrumb span {
     color: rgb(128, 139, 142);
     font-size: 14px;
     font-weight: 400;
     line-height: 120%;
     text-decoration: none;
     margin: 0;
     display: inline-block;
     vertical-align: middle;
}
 .bredcrumb a:hover {
     text-decoration: none;
     opacity: 0.8;
}
 .bredcrumb a:after {
     content: url('../img/bread_arrow.svg');
     display: inline-block;
     vertical-align: middle;
     margin-left: 5px;
}
 .title_main{
     color: rgb(2, 46, 56);
     font-size: 26px;
     font-weight: 500;
     line-height: 120%;
     text-transform: uppercase;
     margin: 0 0 20px 0;
    /* letter-spacing: 0.16px;
    */
}
 @media screen and (min-width: 1024px){
   .title_main{
      font-size: 32px;
      margin: 0 0 24px 0;
   }
 }
 .title_block{
     color: rgb(2, 46, 56);
     font-size: 20px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-transform: uppercase;
     margin: 0 0 16px 0;
}
@media screen and (min-width: 1024px){
   .title_block{
     font-size: 28px;
   }
}
 .title-s_main{
     color: rgb(2, 46, 56);
     font-size: 20px;
     font-weight: 500;
     line-height: 120%;
     text-transform: uppercase;
     margin: 0 0 24px 0;
}
@media screen and (min-width: 1024px){
  .title-s_main{
     font-size: 28px;
   }
}
 .title_main span{
     display: inline-block;
     vertical-align: middle;
}
 .title_span{
     color: rgb(128, 139, 142);
     font-size: 14px;
     font-weight: 400;
     line-height: 120%;
     letter-spacing: 0.16px;
     padding-left: 12px;
     text-transform: none;
}
 .marg0{
     margin: 0;
}
 @media screen and (min-width: 768px){
}
 @media screen and (min-width: 1024px){
}
 .title_center{
     text-align: center;
}
 .decor_row{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: stretch;
    align-items: stretch;
    column-gap: 8px;
}
 .decor-block_title{
     width: auto;
     text-align: center;
}
 .decor-block_title .title_main{
     width: 100%x;
     max-width: 100%;
     letter-spacing: 0.16px;
}
 @media screen and (min-width: 768px){
   .decor-block_title{
     width: 100%;
  }
}
 @media screen and (min-width: 1024px){
   .decor_row{
     column-gap: 16px;
  }
 .decor-block_title{
     width: 432px;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
  }
   .decor-block_title .title_main{
     width: 450px;
  }
}
 @media screen and (min-width: 1024px){
}

 .decor_block{
     width: calc( 50% - 4px );
     padding: 16px;
    /* flex: 16%;
    */
     height: 120px;
     display: block;
     text-decoration: none;
     text-align: center;
     background: rgb(248, 248, 248);
     margin-bottom: 8px;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-end;
     justify-content: flex-end;
     -webkit-align-items: center;
     align-items: center;
     position: relative;
}
 @media screen and (min-width: 768px){
  .decor_block{
     width: calc( 33% - 2.9px );
   }
 }
 @media screen and (min-width: 1024px){
   .decor_block{
     width: 208px;
     padding: 24px;
     height: 208px;
     margin-bottom: 16px;
   }
 }
 .decor_block img{
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     top: 0;
     max-width: 80px;
     max-height: 100%;
}
 @media screen and (min-width: 1024px){
   .decor_block img{
     max-width: 100%;
   }
 }
 .decor_block:hover{
     text-decoration: none;
}
 .decor_title{
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 500;
     line-height: 18px;
     text-align: center;
     text-transform: uppercase;
}
 @media screen and (min-width: 1024px){
   .decor_title{
     font-size: 15px;
     line-height: 19px;
   }
 }
 .decor_block:last-child{
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     background-image: url(../img/home/decor/procent.png);
     background-repeat: no-repeat;
     background-position: right bottom;
}
 .decor_block:last-child .decor_title{
     color: #7C183E;
}
 .tab_wrapper{
}
 .tab_container, .tab-news_container{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     padding-bottom: 0px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
 @media screen and (min-width: 768px){
   .tab_container, .tab-news_container{
     -webkit-justify-content: center;
     justify-content: center;
   }
 }
/* @media screen and (min-width: 1024px){
   .tab_container, .tab-news_container{
     -webkit-justify-content: center;
     justify-content: center;
   }
 }*/
 .tab-container{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     padding-bottom: 24px;
}
 .tab_rewiew-container{}

 @media screen and (min-width: 768px){
   .tab_rewiew-container{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     position: relative;
  }

   .tab_rewiew-container:after{
       content: '';
       width: 100%;
       height: 0;
       position: absolute;
       left: 0;
       bottom: 0;
       border-bottom: 1px solid rgb(232, 232, 232);
  }
}
 .tab-news_container{
     margin-bottom: 32px;
     position: relative;
}
 .tab-news_container:after{
     content: '';
     width: 100%;
     height: 0;
     position: absolute;
     left: 0;
     bottom: 0;
     border-bottom: 1px solid rgb(239, 239, 239);
}
 .tab_c-btn{
     display: inline-block;
     vertical-align: top;
    /* margin: 16px 18px 16px 18px;
    */
     margin: 14px 21px 24px 21px;
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 19px;
     text-transform: uppercase;
     cursor: pointer;
}
 .tab_r-btn{
     color: rgb(128, 139, 142);
     font-size: 18px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-transform: uppercase;
     cursor: pointer;
     padding-right: 0px;
     padding-bottom: 24px;
     position: relative;
     z-index: 1;
     text-align: center;
} 
@media screen and (min-width: 768px){
  .tab_r-btn{
     font-size: 22px;
     padding-right: 40px;
     border-bottom: 1px solid rgb(232, 232, 232);
     text-align: left;
  } 
}
 .tab_r-btn span{
     color: rgb(192, 192, 192);
     font-weight: 400;
     padding-left: 8px;
}
 .tab-o_btn{
     color: rgb(128, 139, 142);
     font-size: 16px;
     font-weight: 400;
     line-height: 120%;
     margin-right: 16px;
     border-bottom: 1px solid transparent;
     cursor: pointer;
}
 @media screen and (min-width: 460px){
   .tab-o_btn{
       font-size: 18px;
       margin-right: 32px;
  }
}
 .tab-o_btn.activ{
     color: rgb(2, 46, 56);
     border-bottom: 1px solid rgb(2, 46, 56);
}
 .tab_c__btn{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 12px;
     color: rgb(158, 158, 158);
     font-size: 16px;
     font-weight: 400;
     line-height: 120%;
     text-align: left;
     text-transform: uppercase;
     margin-right: 48px;
     cursor: pointer;
}
 .tab_c__btn:last-child{
     margin: 0;
}
 .tab_c-icon{
     background: rgb(247, 249, 250);
     width: 36px;
     height: 36px;
     border-radius: 50%;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
}
 .tab_c-btn.activ, .tab_c__btn.activ{
     color: #022E38;
     font-weight: 500;
}
   .tab_r-btn.activ{
       color: rgb(2, 46, 56);
  }
@media screen and (min-width: 768px){
   .tab_r-btn.activ{
       border-bottom: 1px solid rgb(2, 46, 56);
  }
}
 .tab-news_container .tab_c-btn{
     margin: 0px 18px 0px 18px;
     padding-bottom: 24px;
     position: relative;
     z-index: 1;
}
 .tab-news_container .tab_c-btn.activ{
     color: rgb(2, 46, 56);
     border-bottom: 1px solid rgb(2, 46, 56);
}
 .tab_c__btn svg, .tab_c__btn svg path{
     fill: #9E9E9E;
}
 .tab_c__btn.activ svg, .tab_c__btn.activ svg path{
     fill: #022E38;
}
 .tab_c-btn:hover{
     color: #022E38;
}
 .tElem {
     display: -ms-flexbox;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     -ms-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     -ms-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     -ms-flex-pack: justify;
     justify-content: space-between;
     -webkit-align-items: center;
     -ms-flex-align: center;
     align-items: center;
}
 .tContent{
     position: relative;
}
 .tContent_w{
     width: 150%;
     margin-right: -50%;
}
 @media screen and (min-width: 1024px){
  .tContent_w .scrollbar_wrap{
    display: none;
  }
}
 .activ.tContent {
     width: 100%;
     opacity: 1;
     height: auto;
     -webkit-transition: opacity 0.2s ease;
     -moz-transition: opacity 0.2s ease;
     -o-transition: opacity 0.2s ease;
     transition: opacity 0.2s ease;
}
 .activ.tContent_w{
     width: 100%;
}
 @media screen and (min-width: 1024px){
  .activ.tContent_w{
       width: 150%;
  }
}
 .swiperNewBl{
     position: relative;
}
 .swiperAcBl{
     position: relative;
}
 .slider_t-basket{
     color: rgb(2, 46, 56);
     font-size: 22px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0.16px;
     margin-bottom: 16px;
}
 .acsii__img{
     height: 182px;
     margin: 0 0 16px 0;
}
@media screen and (min-width: 1024px){
   .acsii__img{
     height: 240px;
     margin: 0 0 16px 0;
  }
}
 .acsii__img img{
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     display: block;
     -o-object-position: center;
     object-position: center;
}
 .acsii_title{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 450;
     line-height: 120%;
     margin: 0 0 12px 0;
     text-decoration: none;
     display: inline-block;
}
 .acsii_title:hover{
     text-decoration: none;
     opacity: 0.8;
}
 .acsi_date{
     color: rgb(128, 139, 142);
     font-size: 14px;
     font-weight: 400;
     line-height: 120%;
}
 .banner_acsii{
     width: 100%;
     height: 160px;
}
 @media screen and (min-width: 768px){
   .banner_acsii{
     height: 240px;
  }
 }
 .banner_acsii img{
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     display: block;
     -o-object-position: center;
     object-position: center;
}
 .card_block{
     min-height: 276px;
     padding: 20px 12px 16px 12px; 
     border: 1px solid rgb(239, 239, 239);
}
 @media screen and (min-width: 1024px){
   .card_block{
    min-height: 362px;
     padding: 32px;
   }
 }
 .card__top{
     position:relative;
     text-align: center;
}
 @media screen and (min-width: 768px) {
}
 .card__img{
     height: 140px;
     width: 100%;
     margin-bottom: 12px;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
}
 @media screen and (min-width: 1024px){
   .card__img{
     height: 200px;
    margin-bottom: 20px;
   }
 }
 .card__img img{
    /* width: 100%;
     height: 100%;
    */
     width: auto;
     height: auto;
     max-width: 100%;
     max-height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     display: block;
     -o-object-position: center;
     object-position: center;
     transition: all 0.1s linear;
}
 .swiper_basket{
     margin-bottom: 40px;
}
 .swiper_basket .swiper-slide{
     width: auto;
}
 .basket__card{
     min-height: 224px;
     padding: 16px;
     width: 180px;
     border: 1px solid #efefef;
     height: 100%;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
}
 .basket__img{
     height: 100px;
     width: 100px;
     margin: 0 auto 8px auto;
}
 .basket__img img{
     width: auto;
     height: auto;
     max-width: 100%;
     max-height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     display: block;
     -o-object-position: center;
     object-position: center;
}
 .basket_bottom{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     flex: 1 0 auto;
}
 .basket__title{
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 400;
     line-height: 18px;
     margin-bottom: 12px;
     text-decoration: none;
}
 .basket__title:hover{
     opacity: 0.8;
}
 .basket_price_wrap, .basket_price_block{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: center;
     align-items: center;
}
 .basket_price_wrap{
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
}
 .basket_price_block{
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
}
 .btn_buy{
     width: 36px;
     height: 36px;
     background-color: rgb(2, 46, 56);
     background-repeat: no-repeat;
     background-size: auto;
     background-position: center;
     background-image: url(../img/basket.svg);
}
 .basket_price_block .price_old{
     font-size: 14px;
     line-height: 18px;
}
 .basket_price_block .price_new{
     font-size: 16px;
     line-height: 24px;
}
 .basket_price_block .price_curr{
     font-size: 16px;
     line-height: 21px;
}
 .icon_sale{
     position: absolute;
     left: 0px;
     top: 0px;
     border-radius: 1px;
     background: rgb(124, 24, 62);
     padding: 1px 8px 2px 8px;
     color: rgb(255, 255, 255);
     font-size: 14px;
     font-weight: 500;
     line-height: 18px;
}
 @media screen and (min-width: 1024px){
   .icon_sale{
     position: absolute;
     left: -20px;
     top: -20px;
   }
 }
 .card_box.card__banner{
     padding: 0;
}
 .card__banner img{
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     display: block;
     -o-object-position: center;
     object-position: center;
     transition: all 0.1s linear;
}
 .card_title{
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 400;
     line-height: 19px;
     text-align: center;
     margin: 12px 0 6px 0;
     text-decoration: none;
     display: block;
     min-height: 50px;
     text-align: center;
}
 @media screen and (min-width: 1024px){
   .card_title{
     font-size: 15px;
     min-height: 30px;
   }
 }
 .card_title:hover{
     text-decoration: none;
}
 .cart-price_block{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
}
 .price_curr, .price_old, .price_new{
     font-size: 16px;
     font-weight: 500;
     line-height: 21px;
}
 .price_curr{
     color: rgb(2, 46, 56);
}
 .price_new{
     color: #7C183E;
}
 .price_old{
     text-decoration: line-through;
     margin-right: 0.75rem;
     color: #808B8E;
     font-weight: 400;
}
 .color_options{
}
 .radio_elem{
     display: inline-block;
     vertical-align: middle;
     margin: 0 0rem 8px 0;
     position: relative;
}
 .radio_elem input[type='radio'] {
     position: absolute;
     opacity: 0;
     cursor: pointer;
}
 .radio_elem input[type='radio'] + span {
     position: relative;
     width: 60px;
     height: 60px;
     outline: 0;
     cursor: pointer;
     display: block;
     border-radius: 0%;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     padding: 6px;
     border: 1px solid transparent;
}
 .radio_elem input[type='radio'] + span img{
     width: auto;
     max-width: 100%;
     height: auto;
     max-height: 100%;
}
 .radio_elem input[type='radio']:checked + span{
     border: 1px solid rgb(2, 46, 56);
}
 .icon_wish{
     position: absolute;
     right: 0px;
     top: 0px;
     width: 20px;
     height: 20px;
     background-repeat: no-repeat;
     background-image: url(../img/wish.svg);
}
 @media screen and (min-width: 1024px){
   .icon_wish{
     position: absolute;
     right: -12px;
     top: -12px;
   }
 }
 .icon_wish:hover{
     background-image: url(../img/wish_f.svg);
}
 .wedding_container, .new_container{
     
}
 .new_container{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: stretch;
     align-items: stretch;
     gap: 8px;
     width: 100%;
     margin-bottom: 8px;
  }
  .new_container:last-child{
    margin: 0;
  }
  .new_container-wrap{}
 @media screen and (min-width: 1024px){
  .new_container-wrap{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    column-gap: 16px;
  }
   .wedding_container, .new_container{
       display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: row;
       flex-direction: row;
       -webkit-flex-wrap: wrap;
       flex-wrap: wrap;
       -webkit-justify-content: flex-start;
       justify-content: flex-start;
       -webkit-align-items: stretch;
       align-items: stretch;
       gap: 16px;
  }
  .new_container{
    width: 50%;
    margin: 0;
  }
 }
 .wedding_block, .new_block{
     padding: 16px;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-end;
     justify-content: flex-end;
     height: 200px;
     position: relative;
}
 @media screen and (min-width: 1024px){
   .wedding_block, .new_block{
     padding: 32px;
     height: 370px;
   }
 }
 .new_block:before{
     content: '';
     width: 100%;
     height: 40%;
     position: absolute;
     left: 0;
     bottom: 0;
     display: block;
     background: linear-gradient(0.00deg, rgba(0, 0, 0, 0.5 ),rgba(0, 0, 0, 0) 100%);
     z-index: 1;
}
 .wedding_block{
     width: 100%;
     overflow: hidden;
     margin-bottom: 8px;
}

@media screen and (min-width: 1024px){
   .wedding_block{
     width: calc(50% - 8px);
     overflow: hidden;
     margin: 0;
  }
}
 .new_block{
     overflow: hidden;
}
 .new_block img, .wedding_block img{
     transition: all .6s ease 0s;
}
 .new_block:hover img, .wedding_block:hover img{
     transform: scale(1.1);
     opacity: 0.8;
}
/*  .new_block{
    margin-bottom: 8px;
  }*/
  .new_block:last-child{
    margin: 0;
  }
  .new_block:nth-child(1)
    {
     width: 100%;
  }
   .new_block{
    width: calc(50% - 4px);
  }
@media screen and (min-width: 1024px){
   .new_block{
    width: calc(50% - 8px);
    margin: 0;
  }
}
 .wedding_block img, .new_block img{
     position: absolute;
     left: 0;
     top: 0;
     height: 100%;
     width: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     -o-object-position: center;
     object-position: center;
}
 .wedding_title, .new_title{
     color: rgb(255, 255, 255);
     font-weight: 500;
     line-height: 120%;
     text-transform: uppercase;
     margin: 0 0 8px 0;
     position: relative;
     text-align: left;
     z-index: 2;
}
 .wedding_title{
     font-size: 18px;
}
 .new_title{
     font-size: 18px;
}
@media screen and (min-width: 1024px){
   .wedding_title, .new_title{
     margin: 0 0 12px 0;
   }
    .wedding_title{
     font-size: 28px;
    }
     .new_title{
         font-size: 24px;
    }
}
 .wedding_link, .new_link{
     color: rgb(255, 255, 255);
     font-size: 14px;
     font-weight: 450;
     line-height: 120%;
     text-align: left;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 12px;
     text-decoration: none;
     position: relative;
     z-index: 2;
}
@media screen and (min-width: 1024px){
 .wedding_link, .new_link{
     font-size: 15px;
   }
}
 .wedding_link span, .wedding_link svg, .new_link span , .new_link svg{
     display: block;
     line-height: 120%;
}
 .wedding_link svg, .new_link svg{
     margin-top: 4px;
}
 .idea_wrapper{
     margin-bottom: 50px;
}
.adv_container{
  text-align: center;
}
@media screen and (min-width: 1024px){
   .adv_container{
       display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: row;
       flex-direction: row;
       -webkit-flex-wrap: nowrap;
       flex-wrap: nowrap;
       -webkit-justify-content: center;
       justify-content: center;
       -webkit-align-items: flex-start;
       align-items: flex-start;
  }
}
 .adv_block{
     margin: 0 0px 24px 0px;
     width: 190px;
     max-width: 48%;
     display: inline-block;
     vertical-align: top;
}
@media screen and (min-width: 1024px){
   .adv_block{
     margin: 0 20px 0px 20px;
     max-width: 100%;
  }
}
 .adv_img{
     background: rgb(244, 247, 249);
     height: 60px;
     width: 60px;
     margin: 0 auto 16px auto;
     padding: 16px;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     border-radius: 50%;
}
@media screen and (min-width: 1024px){
  .adv_img{
     height: 70px;
     width: 70px;
     margin: 0 auto 20px auto;
   }
}
 .adv_link{
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 500;
     line-height: 120%;
     text-align: center;
     text-decoration: none;
     display: block;
}
@media screen and (min-width: 1024px){
  .adv_link{
     font-size: 16px;
   }
}
 .adv_link:hover{
     text-decoration: none;
     opacity: 0.8;
     color: rgb(2, 46, 56);
}

 .liked_img{
     width: 100%;
     position: relative;
     padding: 0 0;
     height: 260px;
}

@media screen and (min-width: 1024px){
   .liked_row{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: stretch;
     align-items: stretch;
 }
   .liked_img{
     width: 50%;
     position: relative;
     padding: 60px 64px 60px 0;
     height: 720px;
  }
   .liked_img:before{
       content: '';
       width: 300%;
       height: 100%;
       position: absolute;
       display: block;
       right: 230px;
       top: 0px;
       background: rgb(247, 249, 250);
  }
}
 .liked_img img{
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     -o-object-position: center;
     object-position: center;
     position: relative;
}
 .liked_slider{
     width: 100%;
     padding-left: 8px;
     padding-top: 24px;
}
@media screen and (min-width: 1024px){
 .liked_slider{
     width: 50%;
     padding-left: 8px;
     padding-top: 120px;
}
}
 .category_slider{
     position: relative;
}
 .slide__category{
     width: 164px;
     min-height: 190px;
}
 .category_sl{
     background: rgb(248, 248, 248);
     text-align: center;
     padding: 12px 8px;
     width: 100%;
     height: 100%;
     transition: all .6s ease 0s;
}
 .category_sl:hover{
     background: #F8FCFF;
}
 .category__img{
     height: 124px;
     width: 124px;
     margin: 0 auto 4px auto;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
}
 .category__img img{
     width: auto;
     height: auto;
     max-width: 100%;
     max-height: 100%;
}
 .category_title{
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 120%;
     text-decoration: none;
}
 .category_title:hover{
     text-decoration: none;
     opacity: 0.8;
}
 .category__img:hover{
}
 .acsii_wrapper{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     column-gap: 8px;
}
 .acsii_elem{
     width: 100%;
     margin: 0 0 32px 0;
}
@media screen and (min-width: 320px){
   .acsii_wrapper{
     column-gap: 16px;
  }
  .acsii_elem{
     width: calc(50% - 8px);
     margin: 0 0 32px 0;
  }
}
@media screen and (min-width: 1024px){
 .acsii_elem{
     width: calc(33.33% - 11px);
     margin: 0 0 40px 0;
  }
}
@media screen and (min-width: 1200px){
 .acsii_elem{
     width: calc(33.33% - 10.62px);
     margin: 0 0 40px 0;
}
}
  .acsii_img{
       width: 100%;
       height: 140px;
       margin-bottom: 16px;
  }
@media screen and (min-width: 1024px){
   .acsii_img{
       height: 240px;
       margin-bottom: 16px;
  }
}
 .acsii_img img{
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     -o-object-position: center;
     object-position: center;
}
 .acsii_title{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 450;
     line-height: 120%;
     margin-bottom: 16px;
}
 .acsii_date{
     color: rgb(128, 139, 142);
     font-size: 14px;
     font-weight: 400;
     line-height: 120%;
}
@media screen and (min-width: 768px){
   .acsiya_wrap{
       display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: row;
       flex-direction: row;
       -webkit-flex-wrap: nowrap;
       flex-wrap: nowrap;
       column-gap: 16px;
  }
   .acsiya_img, .acsiya_block{
     width: calc( 50% - 8px );
  }
}
  .acsiya_img {
    height: 160px;
  }
  .acsiya_img img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
@media screen and (min-width: 768px){
  .acsiya_img {
    height: inherit;
  }
   .acsiya_img img{
       width: auto;
       max-width: 100%;
       max-height: 100%;
       height: auto;
  }
}
 .acsiya_title{
     color: rgb(2, 46, 56);
     font-size: 26px;
     font-weight: 500;
     line-height: 120%;
     margin: 16px 0 16px 0;
}
@media screen and (min-width: 768px){
   .acsiya_title{
     font-size: 32px;
  }
}
 .acsiya_text, .acsiya_text p, .acsiya_text ul li{
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 140%;
}
 .acsiya_text p, .acsiya_text ul li{
     margin: 4px 0;
}
 .acsiya_text ul{
     list-style: none;
     margin: 10px 0;
     padding: 0;
}
 .countdown_title{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 140%;
     column-gap: 8px;
}
 .countdown_wrapper{
     display: inline-block;
}
 .countdown{
     display: -ms-flexbox;
     display: flex;
     -ms-flex-direction: row;
     flex-direction: row;
     -ms-flex-wrap: wrap;
     flex-wrap: wrap;
     -ms-flex-align: center;
     align-items: center;
     background: rgb(248, 248, 248);
     padding: 6px 20px 6px 20px;
     margin: 8px 0 16px 0;
}
 .countdown-number {
     margin-right: 10px;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: flex-end;
     align-items: flex-end;
    /* -webkit-align-items: center;
     align-items: center;
    */
}
 .countdown-number:last-child{
     margin: 0;
}
 .countdown-time {
     color: rgb(2, 46, 56);
     font-size: 30px;
     font-weight: 500;
     line-height: 30px;
}
 .countdown-text {
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 26px;
     margin-left: 4px;
}
 .footer {
     padding: 32px 0rem 0rem 0rem;
     border-top: 2px solid rgb(178, 140, 106);
}
@media screen and (min-width: 1024px){
   .footer {
     padding: 40px 0rem 0rem 0rem;
   }
}
 .footer_fl{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
}
 .foot__block-contact{
     width: 100%;
     padding-right: 0rem;
     margin-bottom: 24px;
     text-align: center;
}
 @media screen and (min-width: 768px) {
     .foot__block-contact{
         width: 25%;
         padding-right: 0rem;
         margin-bottom: 24px;
         text-align: left;
    }
}
 .logo_foot{
     width: auto;
}
 .logo_foot img{
     width: auto;
     max-width: 100%;
     max-height: 60px;
}
@media screen and (min-width: 768px) {
  .logo_foot img{
     max-height: 100%;
  }
}
 .foot__block-lists{
     width: 100%;
}


 @media screen and (min-width: 768px) {
     .foot__block-lists{
         width: 75%;
         display: -webkit-flex;
         display: flex;
         -webkit-flex-direction: row;
         flex-direction: row;
         -webkit-flex-wrap: nowrap;
         flex-wrap: nowrap;
         -webkit-justify-content: flex-start;
         justify-content: flex-start;
         column-gap: 16px;
         padding-left: 16px;
    }
}

 @media screen and (min-width: 1024px) {
     .foot__block-lists{
        padding-left: 0px;
        column-gap: 32px;
    }
}
 @media screen and (min-width: 1024px) {
   .foot__list-wr{
       width: 25%;
  }
}
 .foot__list-wr:first-child{
     padding-left: 0;
}
 .title_foot-list{
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0.48px;
     text-transform: uppercase;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
      -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: center;
     align-items: center;
     padding: 14px 0 14px 0;
     border-bottom: 1px solid rgb(239, 239, 239);
}
.title_foot-list svg{
  margin: 0 0 0 8px;
  cursor: pointer;
}
/*@media screen and (min-width: 450px){
 .title_foot-list{
      -webkit-justify-content: space-between;
     justify-content: space-between;
   }
}*/
@media screen and (min-width: 768px){
   .title_foot-list svg{
       display: none;
  }
  .title_foot-list{
    border-bottom: 0;
    padding: 0 0 16px 0;
  }
}
 .open .foot__list{
     display: block;
     padding-top: 12px;
}
 .foot__list{
     list-style: none;
     margin: 0;
     padding: 0;
     display: none;
}
@media screen and (min-width: 768px){
     .foot__list{
         display: block;
    }
}

 .foot__list li{
     display: block;
     margin: 0 0 11px 0;
}
 .foot__list li a{
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 19px;
     text-decoration: none;
}
 .foot__list li a:hover{
     text-decoration: none;
     opacity: 0.8;
}
 .contact__link{
     color: rgb(2, 46, 56);
     font-size: 22px;
     font-weight: 400;
     line-height: 31px;
     text-transform: uppercase;
     text-decoration: none;
     margin: 14px 0 12px 0;
     display: block;
}
 .contact__link:hover{
     text-decoration: none;
     opacity: 0.8;
}
 @media screen and (min-width: 1024px){
   .contact__link{
     font-size: 24px;
  }
}
.made_foot-wrapper{
  text-align: center;
}
 .made_foot{
     margin: 0 0 12px 0;
     display: inline-block;
}
@media screen and (min-width: 768px){
  .made_foot-wrapper{
    text-align: left;
  }
  .made_foot{
      display: block;
   } 
 }
 .text_foot{
     margin: 0 0 4px 0;
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 400;
     line-height: 18px;
     text-align: left;
}
 .email_contact{
     font-size: 15px;
     font-weight: 400;
     line-height: 130%;
     margin: 0 0 20px 0;
     width: 260px;
     max-width: 100%;
     display: inline-block;
}
@media screen and (min-width: 768px){
  .email_contact{
    display: block;
  }
}
 .email_contact a{
     font-weight: 500;
     color: #022E38;
     text-decoration: none;
}
 .footer_fl-bottom{
     display: block;
     padding:  22px 0 60px 0;
     text-align: center;
}
 @media screen and (min-width: 768px){
     .footer_fl-bottom{
         display: -webkit-flex;
         display: flex;
         -webkit-flex-direction: row;
         flex-direction: row;
         -webkit-flex-wrap: nowrap;
         flex-wrap: nowrap;
         -webkit-justify-content: space-between;
         justify-content: space-between;
         padding: 32px 0;
         border-top: 1px solid rgb(213, 223, 229);
    }
}
 .copy{
     color: rgb(128, 139, 142);
     font-size: 14px;
     font-weight: 400;
     line-height: 120%;
     text-align: center;
     margin-bottom: 22px;
}
 .card_pay{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     margin-bottom: 22px ;
}
 .card_pay svg{
     margin: 0 18px 0 0;
}
 .foot_dev{
     color: rgb(128, 139, 142);
     font-size: 14px;
     font-weight: 400;
     line-height: 120%;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     text-align: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin-bottom: 24px;
}
 .foot_dev a{
     padding-left: 0.75rem;
}
 @media screen and (min-width: 768px) {
     .copy{
         padding-right: 1rem;
         width: 33%;
         text-align: left;
         margin: 0;
    }
     .card_pay{
         width: 33%;
         -webkit-justify-content: flex-start;
         justify-content: flex-start;
         margin: 0;
    }
     .foot_dev{
         -webkit-flex-wrap: nowrap;
         flex-wrap: nowrap;
         -webkit-justify-content: flex-start;
         justify-content: flex-start;
         text-align: left;
    }
}
 .toplink{
     position: fixed;
     right: 4.5rem;
     bottom: 4.5rem;
     z-index: 10;
}
 .top_link{
     width: 52px;
     height: 52px;
    /* #022E38 */
     background: rgb(2, 46, 56);
     display: block;
     box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
     border-radius: 0%;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     transition: all 0.3s linear;
     cursor: pointer;
}
 @media screen and (max-width: 768px){
     .top_link{
         display: none;
    }
}
 .top_link:hover{
     background: #839283;
}
 .contact_form-wrapper{
     padding: 32px 10px;
     border: 1px solid rgb(223, 233, 236);
     background: rgb(247, 249, 250);
     text-align: center;
}
 .contact_form_tr{
     background: transparent;
     border: none;
     border-top: 1px solid rgb(223, 233, 236);
     padding-bottom: 0;
}
/*.contact_form-wrapper input{
     width: 50%;
}
*/
 @media screen and (min-width: 560px){
     .contact_form-wrapper{
         padding: 32px 50px;
    }
}
 .form_title{
     color: rgb(2, 46, 56);
     font-size: 24px;
     font-weight: 450;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-transform: uppercase;
     margin: 0 0 12px 0;
}
 .form_text{
     width: 580px;
     margin: 0 auto 24px auto;
     max-width: 100%;
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 140%;
}
 .form_contact{
     width: 580px;
     margin: 0 auto 0px auto;
     max-width: 100%;
}
 .control-c_block{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
      -webkit-align-items: flex-start;
    align-items: flex-start;
    /* -webkit-align-content: flex-end;
     align-content: flex-end;
     -webkit-align-items: center;
     align-items: center;*/
     margin: 24px 0;
}
 @media screen and (min-width: 768px) {
     .control-c_block{
         -webkit-flex-wrap: nowrap;
         flex-wrap: nowrap;
    }
}
 .filter_res-block{
     margin: 16px 0 0 0;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
}
 .filter_el{
     margin: 0 6px 6px 0;
}
/*  .filter_elem-container{
    margin-bottom: 24px;
  }
*/
 .filter_el, .filter_elems-close{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 4px;
     width: auto;
     min-height: 29px;
     background: rgb(244, 244, 244);
     padding: 5px 10px 5px 10px;
}
 .filter_el button, .filter_elems-close button{
     width: 14px;
     height: 14px;
     border: none;
     background-image: url(../img/close.svg);
     background-repeat: no-repeat;
     background-position: center;
     background-repeat: no-repeat;
     background-color: transparent;
}
 .filter_elem{
     border: 1px solid rgb(2, 46, 56);
     background: rgb(255, 255, 255);
     padding: 8px 30px;
     height: 42px;
     text-decoration: none;
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 450;
     line-height: 22px;
     text-align: center;
     text-transform: uppercase;
     cursor: pointer;
     margin-bottom: 0px;
     display: inline-block;
}
 .filter_elems-close{
     background: transparent;
     border: 1px solid transparent;
}
 @media screen and (min-width: 768px) {
     .filter_elem{
         margin: 0;
    }
}
 .filter_elem svg, .filter_elem span{
     display: inline-block;
     vertical-align: middle;
     margin: 0 2px;
}
 .sort_elem{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-end;
     justify-content: flex-end;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 8px;
     margin-top: 16px;
}
 .select_box, .title_select{
     display: inline-block;
}
 .title_select{
     color: rgb(128, 139, 142);
     font-size: 16px;
     font-weight: 400;
     line-height: 120%;
     text-align: right;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
}
 select.selectize{
     display: none;
}
 .select_box .nice-select{
    /* -webkit-tap-highlight-color: rgb(178, 140, 106);
    */
     border-radius: 0;
     border: none;
     height: inherit;
     line-height: 18px;
     padding-left: 0;
     padding-right: 24px;
     color: rgb(178, 140, 106);
     font-family: 'FuturaPT';
     font-size: 16px;
     font-weight: 400;
}
 .select_box .current{
     border-bottom: 1px dashed rgb(178, 140, 106);
}
 .select_box .nice-select:after {
     height: 0;
     margin-top: -1px;
     right: 12px;
     top: 50%;
     -ms-transform-origin: 66% 66%;
     transform-origin: 66% 66%;
     -ms-transform: rotate(0deg);
     transform: rotate(0deg);
     transition: all .15s ease-in-out;
     width: 0px;
     border-color: rgb(178, 140, 106) transparent transparent transparent;
     border-style: solid;
     border-width: 4px 4px 0px 4px;
}
 .select_form{
     position: relative;
    /* width: 50%;
    */
     margin: 0 0 16px 0;
     background: #fff;
}
 .select_form select{
     border: 1px solid rgb(232, 232, 232);
     background: white;
     margin: 0 0 0px 0;
     padding: 12px 16px;
     color: rgb(128, 139, 142);
     font-size: 15px;
     font-weight: 400;
     height: 40px;
     width: 100%;
     max-width: 100%;
     appearance: none;
     background: transparent;
     -webkit-appearance: none;
     position: relative;
}
 .select_form:before{
     content: '';
     width: 10px;
     height: 10px;
     display: block;
     position: absolute;
     right: 18px;
     top: 16px;
     border-style: solid;
     transform: rotate(-45deg);
     border-width: 0px 0px 2px 2px;
     border-color: rgb(114, 114, 114);
}
 .select_form select:focus{
     outline: none;
}
 .select_bl{
     position: relative;
}
 .select_bl select{
     border: 1px solid rgb(233, 233, 233);
     color: rgb(17, 17, 17);
     font-size: 13px;
     font-weight: 600;
     line-height: 16px;
     text-transform: uppercase;
     padding: 0 20px 0 0;
     margin: 0;
     text-align: right;
     appearance: none;
     background: transparent;
     -webkit-appearance: none;
     position: relative;
}
 .select_bl:before{
     content: '';
     width: 10px;
     height: 10px;
     display: block;
     position: absolute;
     right: 0px;
     top: 8px;
     border-style: solid;
     border-width: 6px 6px 0px 6px;
     border-color: rgb(17, 17, 17) transparent transparent transparent;
}
 .select_bl select:focus{
     outline: none;
     border: none;
}
 .filter-elem{
     border-bottom: 1px solid rgb(239, 239, 239);
     padding: 0px 0;
}
 .filter-body{
     padding: 0px 0 16px 0;
}
 @media screen and (min-width: 768px) {
}
 .range-elem .noUi-connect {
     background: rgb(0, 0, 0);
}
 .range-elem .noUi-horizontal .noUi-handle{
     width: 17px;
     height: 17px;
     border: 4px solid rgb(17, 17, 17);
     border-radius: 50%;
     background: #FFF;
     cursor: default;
     box-shadow: none;
     top: -7px;
}
 .range-elem{
     margin: 26px 0 10px 0;
}
 .range-elem .noUi-handle:after, .range-elem .noUi-handle:before{
     display: none;
}
 .range-elem .noUi-horizontal {
     height: 2px;
}
 .range-elem .noUi-target {
     background: rgb(233, 233, 233);
     border-radius: 10px;
     border: none;
     box-shadow: none;
}
 .range_inputs{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: stretch;
     align-items: stretch;
     column-gap: 10px;
}
 .choose__input{
     border: 1px solid rgb(239, 239, 239);
     background: rgb(239, 239, 239);
     width: 50%;
     height: 36px;
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 20px;
     text-align: center;
     padding: 0 12px;
     line-height: 36px;
}
 .body_js{
     height: 0;
     opacity: 0;
     visibility: hidden;
     min-height: 0;
     transition: min-height 0.2s linear;
}
 .openBlock .body_js{
     height: auto;
     opacity: 1;
     visibility: visible;
}
 .openBlock .filter-body{
}
 .catalog__wrapper{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: stretch;
     align-items: stretch;
     margin-left: -8px;
     margin-right: -8px;
     margin-top: 24px;
     margin-bottom: 40px;
}
 .card__img {
     width: 100%;
}
 .card_box{
     width: 100%;
     min-height: 276px;
     padding: 20px 12px 16px 12px;
     border: 1px solid rgb(239, 239, 239);
}
 @media screen and (min-width: 320px) {
     .card_box{
         width: 50%;
    }
}
 @media screen and (min-width: 768px) {
     .card_box{
         width: 33.33%;
         padding-bottom: 3.5rem;
    }
}

 @media screen and (min-width: 1024px) {
     .card_box{
         width: 20%;
         min-height: 362px;
         padding: 32px 32px 20px 32px;
    }
}
 .elem_op-block{
     margin: 0 0 16px 0;
}
 @media screen and (min-width: 768px){
     .elem_op-block{
         margin: 0 0 12px 0;
    }
}
 .elem_op_title{
     margin: 0 0 0px 0;
     padding: 12px 0 12px 0;
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 120%;
     text-transform: uppercase;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
}
 .size_link{
     color: rgb(178, 140, 106);
     font-size: 15px;
     font-weight: 400;
     line-height: 120%;
     text-decoration: none;
     text-transform: none;
     margin: 0px 0 0px 0;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 7px;
     cursor: pointer;
}
 .size_link span{
     border-bottom: 1px dashed rgb(178, 140, 106);
}
 .size_link:hover{
     text-decoration: none;
}
 .elem_op_title svg{
     margin-top: 6px;
}
 .btn_cartt_wr{
     margin: 24px 0 18px 0;
}
 .btn_cart, .btn_cart_tr{
     position: relative;
     display: inline-block;
     text-decoration: none;
     color: rgb(255, 255, 255);
     font-size: 14px;
     font-weight: 500;
     line-height: 24px;
     letter-spacing: 0.32px;
     text-align: center;
     text-transform: uppercase;
     padding: 10px 10px;
     height: 46px;
     border-radius: 0;
     transition: all 0.3s linear;
}
 .btn_cartt_wr .btn_cart, .btn_cartt_wr .btn_cart_tr{
     width: 100%;
}
 @media screen and (min-width: 350px){
     .btn_cart{
         padding: 10px 24px;
    }
}
 .btn_cart{
     color: white;
     background: rgb(2, 46, 56);
     border: 1px solid rgb(2, 46, 56);
}
 .btn_cart:hover {
     color: #839283;
     background: #fff;
     border: 1px solid #839283;
}
 .btn_cart_tr{
     color: rgb(2, 46, 56);
     background: #fff;
     border: 1px solid rgb(2, 46, 56);
}
 .btn_cart_tr:hover{
     color: white;
     background: rgb(2, 46, 56);
     border: 1px solid rgb(2, 46, 56);
}
 .order_btn_total {
     margin: 0 0 20px 0;
}
 .order_btn_total .btn_cart{
     height: 52px;
     width: 100%;
}
 .quantity_block {
    /* max-width: 100%;
    */
     margin: 12px 0 12px 0;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-direction: row;
     flex-direction: row;
     -ms-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -ms-flex-align: center;
     align-items: center;
}
 .quantity_block input[type='number']{
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     height: 32px;
     width: 36px;
     padding: 0 0px;
     line-height: 32px;
     text-align: center;
     margin: 0 0;
     border: 1px solid transparent;
     display: inline-block;
     vertical-align: middle;
     appearance: textfield;
     -webkit-appearance: textfield;
     -moz-appearance: textfield;
}
 .quantity_block input[type='number']::-webkit-inner-spin-button {
     -webkit-appearance: none;
}
 .quantity_block input[type='number']::-webkit-outer-spin-button {
     -webkit-appearance: none;
}
 .choose_elem {
     position: relative;
     height: 32px;
     line-height: 32px;
     text-align: center;
     width: 32px;
     cursor: pointer;
     border: 1px solid rgb(239, 239, 239);
     background: rgb(239, 239, 239);
     border-radius: 0px;
     outline: none;
     display: inline-block;
     vertical-align: middle;
     transition: background 0.2s linear;
}
 .choose_elem:hover{
     background: #f7f7f7;
}
 .minus:after, .plus:after, .plus:before{
     content: '';
     display: block;
     position: absolute;
     background: rgb(2, 46, 56);
}
 .minus:after, .plus:after{
     width: 11px;
     height: 1px;
     left: 9px;
     top: 14px;
}
 .plus:before{
     height: 11px;
     width: 1px;
     top: 9px;
     left: 14px;
}
 .pagination_block{
     margin: 24px 0 24px 0;
}
 .pagination_block ul{
     text-align: center;
     list-style: none;
     margin: 0;
     padding: 0;
}
 .pagination_block ul li{
     display: inline-block;
     vertical-align: middle;
     margin: 0;
     padding: 0;
}
 .pagination_block ul li:before{
     display: none;
}
 .pagination_block ul li a{
     min-width: 40px;
     height: 40px;
     text-align: center;
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 30px;
     text-align: center;
     display: block;
     padding: 5px;
     transition:all 0.2s ease;
     -webkit-transition:all 0.2s ease;
     -moz-transition:all 0.2s ease;
     -o-transition:all 0.2s ease;
     text-decoration: none;
}
 @media screen and (min-width: 768px) {
     .pagination_block ul li a{
         min-width: 48px;
         height: 48px;
         line-height: 38px;
    }
}
 .pagination_block ul li.active a, .pagination_block ul li:not(:first-child) a:hover, .pagination_block ul li:not(:last-child) a:hover{
     text-decoration: none;
}
 .pagination_block ul li.active a{
     background: rgb(17, 17, 17);
     color: #fff;
}
 .pagination_block ul li:not(:first-child) a:hover, .pagination_block ul li:not(:last-child) a:hover{
     background: #F8F8F8;
     color: rgb(2, 46, 56);
}
 .pagination_block ul li:first-child a:hover, .pagination_block ul li:last-child a:hover{
     color: rgb(2, 46, 56);
     background: transparent;
     opacity: 0.8;
}
 .pagination_block ul li:first-child a, .pagination_block ul li:last-child a{
     line-height: 42px;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 20px;
}
 .pagination_block ul li:first-child a{
     margin-right: 12px;
}
 .pagination_block ul li:last-child a{
     margin-left: 12px;
}
 .pagination_block ul li a:focus{
     background: rgb(17, 17, 17);
     color: #FFFFFF;
}
 .pagination_block ul li a svg, .pagination_block ul li a path{
     fill: rgb(246, 246, 246);
}
/*.pagination_block ul li a:hover svg, .pagination_block ul li a:hover path{
     fill: rgb(17, 17, 17);
}
*/
 .pagination_block ul li a:focus svg, .pagination_block ul li a:focus path{
     fill: rgb(17, 17, 17);
}
 @media screen and (max-width: 768px){
  .pagination_block ul li:first-child a span, 
  .pagination_block ul li:last-child a span{
    display: none;
  }
 }
 .load_more-wrapper{
     text-align: center;
     margin: 16px auto;
}
 .load_more{
     padding: 10px 60px;
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 450;
     line-height: 22px;
     letter-spacing: 0.16px;
     text-align: center;
     text-decoration: none;
     text-transform: uppercase;
     border: 1px solid rgb(2, 46, 56);
     background: transparent;
     display: inline-block;
}
 .load_more svg{
     transition:all 0.2s ease;
     -webkit-transition:all 0.2s ease;
     -moz-transition:all 0.2s ease;
     -o-transition:all 0.2s ease;
}
 .load_more svg, .load_more span{
     display: inline-block;
     vertical-align: middle;
     margin: 0 6px;
}
 .load_more:hover{
     text-decoration: none;
}
 .load_more:hover svg{
     transform: rotate(180deg);
}
 .result-wrapper{
     color: rgb(128, 139, 142);
     font-size: 14px;
     font-weight: 400;
     line-height: 18px;
     text-align: center;
     margin: 0 0 16px 0;
}
 .swiperBanner{
     position: relative;
     height: 100%;
}
 .swiperAddBl, .swiperRecomBl, .swiperSeeBl{
     position: relative;
}
 .swiper-slide{
     height: auto;
}
 .slide__elem-img{
     height: 100%;
     display: block;
     text-decoration: none;
}
 .slide__elem-img img{
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     display: block;
}
.prev_st.swiper-button-prev, 
.next_st.swiper-button-next{
  display: none;
 }
 .button_control .swiper-button-prev, 
 .button_control .swiper-button-next{
     width: 30px;
     height: 60px;
     transition: all 0.3s linear;
}
 .swiper_st .button_control .swiper-button-prev{
    /* top: 200px;
    */
     top: 186px;
     display: none;
     margin: 0;
     transform: none;
}
 @media screen and (min-width: 768px){
  .prev_st.swiper-button-prev, 
  .next_st.swiper-button-next{
      display: -ms-flexbox;
      display: flex;
  }
     .swiper_st .button_control .swiper-button-prev {
         display: -ms-flexbox;
         display: flex;
    }

}
 .prev_t, .next_t{
     background: transparent;
     opacity: 0;
}
 .prev_st, .next_st{
}
 .swiper_cart svg, .swiper_cart svg path, .prev_st svg, .next_st svg, .prev_st svg path, .next_st svg path{
     fill: #022E38;
}
 .prev_t{
     left: 24px;
}
 .next_t{
     right:24px;
}
 .swiperBanner:hover .prev_t, .swiperBanner:hover .next_t{
     opacity: 1;
}
 .swiper_cart{
     background: #fff;
     height: 60px;
     width: 30px;
     text-align: center;
}
 .swiper_trumb .swiper-slide{
     height: 120px;
}
 .swiper_cart.swiper-button-prev {
     left: -36px;
     transition: left 0.3s linear;
}
 .swiper_cart.swiper-button-next {
     right: -36px;
     transition: right 0.3s linear;
}
/* .swiper_cart.swiper-button-prev svg, .swiper_cart.swiper-button-next svg{
     width: 16px;
     height: 16px;
}*/
 .button_control .swiper-button-prev svg, .button_control .swiper-button-next svg{
     width: auto;
     height: auto;
}
 .swiper__card:hover .swiper_cart.swiper-button-prev {
     left: -4px;
}
 .swiper__card:hover .swiper_cart.swiper-button-next {
     right: -4px;
}
 .prev_t:hover svg, .next_t:hover svg, .prev_t:hover svg path, .next_t:hover svg path{
     fill: #111;
}
 .prev_t:hover, .next_t:hover{
     background: #fff;
}
 .prev_st:hover svg, .next_st:hover svg, .prev_st:hover svg path, .next_st:hover svg path{
     fill: #fff;
}
 .prev_st:hover, .next_st:hover{
     background: #111111;
     border: 1px solid #111111;
}
 .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after, .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
     display: none;
}
 .swiper-top__wrapper{
     position: relative;
}
 @media screen and (max-width: 767px){
     .swiper-top__wrapper{
         position: relative;
         width: 28.75rem;
         margin-right: 8.25rem;
    }
     .swiper_cart.swiper-button-prev , .swiper_cart.swiper-button-next{
         display: none;
    }
}
 .swiperReview__wrapper{
     position: relative;
     width: 180%;
     margin-right: -80%;
}
 @media screen and (min-width: 768px){
     .swiper-top__wrapper{
         width: 100%;
         margin-right: inherit;
    }
}
 .swiper_sr{
     position: relative;
     margin: 1.5rem 0;
}
 .swiper_sr .button_control{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
}
 .swiper_sr .swiper-button-prev, .swiper_sr .swiper-button-next{
     position: relative;
     top: inherit;
     margin: inherit;
     left: inherit;
     right: inherit;
     transform: inherit;
}
 .swiper_sr .swiper-button-prev{
     margin: 0 0.875rem 0 0;
}
 .swiper_sr .swiper-button-next{
}
 .swiper_st .swiper-button-prev{
     left: 1rem;
}
 .swiper_st .swiper-button-next{
     right: 1rem;
}
 @media screen and (min-width: 1300px){
     .swiper_sr .swiper-button-next{
    }
     .swiper_st .swiper-button-prev{
         left: -50px;
    }
     .swiper_st .swiper-button-next{
         right: -50px;
    }
}
 .sl_line{
     width: 63px;
     margin: 0 100px 0 12px;
     display: inline-block;
     vertical-align: middle;
     height: 1px;
     background: #fff;
}
 .swiper-pagination-current{
     min-width: 20px;
}
 .scrollbar_wrap{
     width: 520px;
     max-width: 100%;
     position: relative;
     margin: 24px auto;
}
 .pagination_wrap{
     width: 520px;
     max-width: 100%;
     position: relative;
     margin: 24px auto 0 ;
}
@media screen and (min-width: 1024px){
   .pagination_wrap{
     margin: 32px auto;
  }
}
 .pagination_wrap .pagination_acsii ,
 .pagination_wrap .pagination_cart{
     position: relative;
     top: inherit;
     bottom: inherit;
     left: inherit;
     right: inherit;
     transform: inherit;
}
@media screen and (min-width: 768px){
  .pagination_wrap .pagination_cart{
    display: none;
  }
}

 .scrollbar_wrap .swiper-horizontal > .swiper-scrollbar, .scrollbar_wrap .swiper-scrollbar.swiper-scrollbar-horizontal{
     height: 2px;
     background: rgb(242, 242, 242);
}
 .scrollbar_wrap .swiper-scrollbar-drag{
     background: rgb(43, 43, 43);
}
 .section{
     padding: 0 0 60px 0;
}


 .section_decor{
     padding: 42px 0 60px 0;
}
 .section_wedding{
     padding: 0px 0 60px 0;
}
 .section_new{
     padding: 0px 0 60px 0;
}
 .section_acsii{
     padding: 0px 0 60px 0;
}
 .section_ju{
     padding: 0px 0 60px 0;
}
.section_seo{
  padding-bottom: 28px;
}


 .section_recom{
     padding: 60px 0 1rem 0;
}

 .section_see{
     padding: 40px 0 40px 0;
}
 .section_news{
     padding: 0px 0 56px 0;
}
 .section_bottom{
     padding: 0px 0 80px 0;
}
 .section_article{
     padding: 8px 0 40px 0;
}
 .order_section{
     padding: 40px 0 40px 0;
}
 .section_page{
     padding: 0px 0 26px 0;
}
.section_p{
  padding: 0 0  60px 0;
}


 @media screen and (min-width: 768px){
     .section{
         padding: 32px 0;
    }
     .section_decor{
         padding: 32px 0 48px 0;
    }
     .section_wedding{
         padding: 70px 0 70px 0;
    }
     .section_new{
         padding: 32px 0 70px 0;
    }
     .section_acsii{
         padding: 32px 0 16px 0;
    }
     .section_ju{
         padding: 70px 0 32px 0;
    }
     .section_recom{
         padding: 60px 0 1rem 0;
    }
     .section_see{
         padding: 40px 0 40px 0;
    }
     .section_news{
         padding: 0px 0 56px 0;
    }
     .section_bottom{
         padding: 0px 0 80px 0;
    }
     .section_article{
         padding: 8px 0 40px 0;
    }
     .order_section{
         padding: 40px 0 40px 0;
    }
     .section_page{
         padding: 0px 0 26px 0;
    }
}
 .check_el{
     display: block;
     margin: 0 0 10px 0;
     position: relative;
     display: block;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
}
 .check_el input[type='checkbox'], .check_el input[type='radio'] {
     position: absolute;
     opacity: 0;
     cursor: pointer;
     width: 100%;
     height: 100%;
}
 .check_el input[type='checkbox'] + span, .check_el input[type='radio'] + span{
     position: relative;
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 120%;
     padding: 0 0px 0 32px;
     width: auto;
     outline: 0;
     cursor: pointer;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
}
 .check_el input[type='checkbox'] + span:before, .check_el input[type='radio'] + span:before, .check_el input[type='checkbox'] + span:after, .check_el input[type='radio'] + span:after{
     content: '';
     display: block;
     position: absolute;
}
 .check_el input[type='checkbox'] + span:before, .check_el input[type='radio'] + span:before{
     width: 20px;
     height: 20px;
     border: 1px solid rgb(220, 220, 220);
     left: 0;
     top: 0;
}
 .check_el input[type='checkbox'] + span:after, .check_el input[type='radio'] + span:after{
     top: 5px;
     left: 4px;
     width: 12px;
     height: 6px;
     border-style: solid;
     border-width: 2px;
     border-color: transparent transparent #ffffff #ffffff;
     -ms-transform: rotate(315deg);
     transform: rotate(315deg);
     border-radius: 0%;
     opacity: 0;
}
 .check_el input[type='checkbox']:checked + span:before, .check_el input[type='radio']:checked + span:before{
     background: rgb(2, 46, 56);
}
 .check_el input[type='checkbox']:checked + span:after, .check_el input[type='radio']:checked + span:after{
     opacity: 1;
}
 .check_el input[type='checkbox'] + span i, .check_el input[type='radio'] + span i{
     width: 20px;
     height: 20px;
     display: inline-block;
     margin-right: 10px;
     border-radius: 50%;
}
 .buyer_wrapper{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: center;
     align-items: center;
     width: 100%;
     margin-bottom: 20px;
}
 .buyer_title{
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 140%;
}
 .buyer_radio{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-end;
     justify-content: flex-end;
     -webkit-align-items: center;
     align-items: center;
}
 .radio_el{
     display: inline-block;
     vertical-align: middle;
     margin: 0 0rem 0px 16px;
     position: relative;
}
 .radio_el input[type='radio'] {
     position: absolute;
     opacity: 0;
     cursor: pointer;
}
 .radio_el input[type='radio'] + span {
     position: relative;
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 22px;
     padding-left: 30px;
     min-height: 22px;
}
 .radio_el input[type='radio'] + span:before{
     content: '';
     width: 22px;
     height: 22px;
     border-radius: 50%;
     border: 1px solid rgb(220, 220, 220);
     position: absolute;
     left: 0;
     top: 0;
}
 .radio_el input[type='radio']:checked + span:before{
     border: 5px solid rgb(2, 46, 56);
}
 .check_elem{
     display: inline-block;
     vertical-align: middle;
     margin: 0 6px 6px 0;
     position: relative;
}
 .check_elem input[type='checkbox'], .check_elem input[type='radio'] {
     position: absolute;
     opacity: 0;
     cursor: pointer;
}
 .check_elem input[type='checkbox'] + span, .check_elem input[type='radio'] + span{
     position: relative;
     background: rgb(248, 248, 248);
     border: 1px solid rgb(248, 248, 248);
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 500;
     text-align: center;
     font-weight: 400;
     line-height: 17px;
     padding: 10px 12px;
     width: auto;
     min-width: 60px;
     height: 37px;
     outline: 0;
     cursor: pointer;
     display: block;
     border-radius: 0%;
     text-align: center;
}
 @media screen and (min-width: 768px){
  .check_elem input[type='checkbox'] + span, 
  .check_elem input[type='radio'] + span{
    min-width: 66px;
  }
 }
 .check_elem:hover input[type='checkbox'] + span, .check_elem:hover input[type='radio'] + span, .check_elem input[type='checkbox']:checked + span, .check_elem input[type='radio']:checked + span{
     border: 1px solid rgb(2, 46, 56);
}
 .check_elem input[type='checkbox']:disabled + span, .check_elem input[type='radio']:disabled + span{
     opacity: 0.4;
}
 .size__opts{
     margin: 0 0 6px 0;
}
 .size__weigth{
     color: rgb(128, 139, 142);
     font-size: 15px;
     font-weight: 400;
     line-height: 120%;
}
 .order_check{
     margin: 0 0 24px 0;
}
 .order_check input[type='checkbox'] + span, .order_check input[type='radio'] + span{
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 120%;
}
 .text-seo__container{
     margin-bottom:32px;
}
 .text-seo__block{
     height: 6rem;
     position: relative;
     overflow: hidden;
     transition: height 0.4s linear;
}
 .text-seo__block p:last-child{
     margin: 0;
}
 .text-seo__block:after{
     content: '';
     width: 100%;
     height: 80%;
     position: absolute;
     left: 0;
     bottom: -2px;
     background: rgb(255,255,255);
     background: linear-gradient(0deg, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 100%);
}
 .text-seo__btn{
     margin: 16px 0;
     text-align: left;
}
 @media screen and (min-width: 768px) {
    /* .text-seo__block{
         height: 15rem;
    }
    */
}
 .t-seo__btn{
     color: rgb(178, 140, 106);
     font-size: 14px;
     font-weight: 400;
     line-height: 18px;
     letter-spacing: 2px;
     text-align: left;
     border-bottom: 1px dashed rgba(0, 0, 0, 0.26);
     cursor: pointer;
     display: inline-block;
}
 .open .text-seo__block{
     height: auto;
}
 .open .text-seo__block:after{
     display: none;
}
 .text-seo__fl{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-content: space-between;
     align-content: space-between;
     -webkit-align-items: stretch;
     align-items: stretch;
}
 .text-seo{
     padding-right: 8px;
}
 .seo_img{
     flex: 0 0 25%;
     padding-left: 8px;
     text-align: center;
}
 .seo_img img{
     width: auto;
     max-width: 100%;
}
 .section_informs{
     border-top: 1px solid rgb(239, 239, 239);
     padding-bottom: 40px;
}
 .informs_container{}

 .informs_block{
     width: 100%;
     border-bottom: 1px solid rgb(239, 239, 239);
     padding: 24px 0;
}
 .informs_block:last-child{
     border: none;
}
@media screen and (min-width: 1024px){
   .section_informs{
     padding-bottom: 0px;
    }
   .informs_container{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: stretch;
     align-items: stretch;
  }

   .informs_block{
       width: 33%;
       border-right: 1px solid rgb(239, 239, 239);
       padding: 42px;
  }
   .informs_block:first-child{
     padding-left: 0;
  }
   .informs_block:last-child{
       border: none;
       padding-right: 0;
  }
}
 .informs_title{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 450;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-transform: uppercase;
     margin: 0 0 12px 0;
}
 .informs_text{
     color: rgb(128, 139, 142);
     font-size: 15px;
     font-weight: 400;
     line-height: 140%;;
     text-align: left;
     margin: 0 0 16px 0;
}
@media screen and (min-width: 1024px){
  .informs_text{
      margin: 0 0 20px 0;
    }
}
 .informs_link{
     display: inline-block;
     text-decoration: none;
     border: 1px solid rgb(178, 140, 106);
     height: 42px;
     padding: 10px 32px;
     color: rgb(178, 140, 106);
     font-size: 14px;
     font-weight: 450;
     line-height: 22px;
     letter-spacing: 0.32px;
     text-align: center;
     text-transform: uppercase;
}
.informs_link:hover{
  background: rgb(178, 140, 106);
  color: #fff;
}

 .subscribe__form{
     position: relative;
}
 .subscribe__form input[type='text'], .subscribe__form input[type='email'] {
     width: 100%;
     max-width: 100%;
     border: 1px solid rgb(220, 220, 220);
     color: rgb(128, 139, 142);
     font-size: 15px;
     line-height: 22px;
     font-weight: 400;
     padding: 10px 16px 10px 16px;
}
 .subscribe__form input[type='text']:focus, .subscribe__form input[type='email']:focus {
     outline: none;
}
 .btn_subscribe{
     background: rgb(2, 46, 56);
     color: rgb(255, 255, 255);
     font-size: 14px;
     font-weight: 450;
     line-height: 18px;
     letter-spacing: 0.32px;
     text-align: center;
     text-transform: uppercase;
     text-decoration: none;
     display: inline-block;
     padding: 0 32px;
     height: 100%;
     position: absolute;
     right: 0;
     top: 0;
}
/* @media screen and (min-width: 768px) {
}*/
/*
 .social_el{
     border-top: 1px solid rgb(219, 219, 219);
     border-left: 1px solid rgb(219, 219, 219);
     border-bottom: 1px solid rgb(219, 219, 219);
     display: inline-block;
     width: 8rem;
     height: 3rem;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
}
 @media screen and (min-width: 768px) {
  .social_el{
     border-top: 1px solid rgb(219, 219, 219);
     border-left: 1px solid rgb(219, 219, 219);
     border-bottom: 1px solid rgb(219, 219, 219);
     display: inline-block;
     width: 8rem;
     height: 3rem;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
  }
}
 .social_el:last-child{
     border-right: 1px solid rgb(219, 219, 219);
}
 .social_el:hover, .soc_el:hover{
     text-decoration: none;
}
 .soc_el{
     margin: 16px 16px 16px 0 ;
}
 .social_el svg, .social_el svg path, .soc_el svg, .soc_el svg path{
     transition: all 0.3s ease;
}
 .social_el:hover svg, .social_el:hover svg path, .soc_el:hover svg, .soc_el:hover svg path{
     fill: #839283;
}*/
 .btn_tr{
     border: 1px solid rgb(2, 46, 56);
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 500;
     letter-spacing: 0.32px;
     height: 46px;
     line-height: 44px;
     text-align: center;
     text-transform: uppercase;
     display: inline-block;
     padding: 0 48px;
     text-decoration: none;
     position: relative;
     overflow: hidden;
     background: transparent;
}
 .btn_tr span{
     position: relative;
     z-index: 2;
}
 .btn_tr:hover{
     color: #fff;
}
 .btn_tr:after{
     content: '';
     background: rgb(2, 46, 56);
     top: 100%;
     left: 0;
     width: 100%;
     height: 100%;
     transition: all 0.2s linear;
     display: block;
     position: absolute;
}
 .btn_tr:hover:after{
     top: 0%;
}
 .start__block{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-content: stretch;
     align-content: stretch;
     -webkit-align-items: center;
     align-items: center;
     margin: 0px 0 0px 0;
}
.review_cart_result-block
.start__block{
   -webkit-flex-wrap: wrap;
   flex-wrap: wrap;

}

 .star__elems{
     display: -ms-flexbox;
     display: flex;
     -ms-flex-direction: row;
     flex-direction: row;
     -ms-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -ms-flex-pack: start;
     justify-content: flex-start;
     -ms-flex-align: center;
     align-items: center;
     margin: 0 2px 0 0;
}
 .star_marg2{
     margin: 0px 0 24px 0;
}
 .star {
     display: inline-block;
     vertical-align: middle;
     margin: 0 2px 0 4px;
}
 @media screen and (min-width: 768px) {
     .star {
         margin: 0 4px 0 0px;
    }
     .star_marg1{
         margin: 12px 0 20px 0;
    }
}
 .star svg{
     max-height: 100%;
     max-width: 100%;
}
 .star__text{
     color: rgb(128, 139, 142);
     font-size: 16px;
     font-weight: 400;
     line-height: 21px;
     white-space: nowrap;
}

 .star__text-no{
     color: rgb(128, 139, 142);
     font-size: 15px;
     font-weight: 400;
     line-height: 120%;
}
 .cookies__wrapper{
     position: fixed;
     z-index: 120;
     left: 0;
     bottom: 12px;
     width: 100%;
}
 .cookies_block{
     width: 1600px;
     max-width: 100%;
     margin: 0 auto;
     padding: 12px 12px 12px 12px;
     background: rgb(2, 46, 56);
}
 @media screen and (min-width: 768px){
     .cookies_block{
        display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: row;
       flex-direction: row;
       -webkit-flex-wrap: nowrap;
       flex-wrap: nowrap;
       -webkit-justify-content: space-between;
       justify-content: space-between;
       -webkit-align-items: center;
       align-items: center;
       padding: 12px 12px 12px 24px;
    }
}
 .coockies_title{
     color: rgb(255, 255, 255);
     font-size: 13px;
     font-weight: 400;
     line-height: 19px;
     margin: 0 0 8px 0;
}
 @media screen and (min-width: 768px){
     .coockies_title{
         font-size: 15px;
         margin: 0;
    }
}
.cookie__btns{
  text-align: center;
}
 .cookie__btns button{
     padding: 10px 3rem;
     white-space: nowrap;
     margin: 12px auto 0 auto;
}
 @media screen and (min-width: 768px){
   .cookie__btns button{
     margin: 0 0 0 24px;
  }
  .cookie__btns{
    text-align: right;
  }
 }
 .error_container, .thank_container{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     flex: 1 0 auto;
     min-height: calc( 100vh - 240px );
     padding: 40px 0 ;
}
 .thank_text-block{
     width: 670px;
     max-width: 100%;
     padding: 0 10px;
     margin: 0 auto;
     text-align: center;
}
 .error_text-block{
     width: 680px;
     max-width: 100%;
     margin: 0 auto;
     text-align: center;
}
 .error_img{
     margin: 0 auto;
     max-width: 100%;
     margin: 0 0 40px 0;
}
 .error_img img{
     width: auto;
     height: auto;
     max-width: 100%;
     max-height: 100%;
}
 .error_text-top{
     color: rgb(2, 46, 56);
     font-size: 24px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-align: center;
     text-transform: uppercase;
     margin: 0 0 12px 0;
}
 .error_text{
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 400;
     line-height: 140%;
     text-align: center;
     margin: 0 0 24px 0;
}
 @media screen and (min-width: 768px){
   .error_text-top{
       font-size: 32px;
  }
   .error_text{
       font-size: 16px;
  }
 }
 .error_btn{
     text-align: center;
}
 .thank_img{
     margin: 0 auto;
     max-width: 100%;
     margin: 0 0 24px 0;
}
 .thank_img svg{
     width: auto;
     height: auto;
     max-width: 100%;
     max-height: 100%;
}
 .thank_text-top{
     color: rgb(2, 46, 56);
     font-size: 26px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-transform: uppercase;
     margin: 0 0 24px 0;
}
@media screen and (min-width: 1024px) {
 .thank_text-top{
     font-size: 32px;
   }
}
 .thank_text-order{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0.16px;
     margin: 0 0 8px 0;
}
 .thank_text-n{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 400;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-align: center;
     margin: 0 0 24px 0;
}
 .thank_text-m{
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 140%;
     margin: 0 0 24px 0;
}
 .thank_text-block .social_elems{
     -webkit-justify-content: center;
     justify-content: center;
}
 ::-webkit-scrollbar-thumb {
     background: #c8c5c5;
     width: 8px;
     height: 4px 
}
 ::-webkit-scrollbar {
     width: 8px;
     background-color: #f2f2f2;
     height: 4px 
}
 .cart_wrapper{
}
 .cart_wrapper:after, .cart_wrapper:before{
     content: '';
     width: 100%;
     display: block;
     clear: both;
}
 .cart_img{
     width: 100%;
     float: left;
}
 .cart_text{
     width: 100%;
     float: right;
     margin: 24px 0 0 0;
     position: relative;
}
 .section_reccom{
     background: rgb(247, 249, 250);
     padding: 40px 0;
     margin: 40px 0 0 0;
}
 .recoment_block{
     width: 100%;
}
 @media screen and (min-width: 768px){
     .cart_img{
         width: 60%;
    }
     .cart_text{
         width: 40%;
         margin: 0;
    }
     .section_reccom{
     padding: 60px 0;
    }
}
 @media screen and (min-width: 1024px){
     .cart_img{
         width: 67%;
    }
     .cart_text{
         width: 33%;
         padding-left: 6px;
    }
     .recoment_block{
         width: 83%;
         margin: 0 auto;
    }
}
 .cart_sliders{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row-reverse;
     flex-direction: row-reverse;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: stretch;
     align-items: stretch;
     column-gap: 16px;
}
 .swiper_cart_wrap{
     width: 100%;
     margin-right: 0px;
}

@media screen and (min-width: 768px) and (max-width: 1199px){
     .swiper_trumb_wrap{
         display: none;
    }
     .swiper_cart_wrap{
/*      width: 640px;*/
      max-width:  calc( 100% - 32px );
      margin-left: 16px;
      margin-right: 16px;
    }
}
 @media screen and (min-width: 1200px){
     .swiper_trumb_wrap{
         width: 120px;
    }
     .swiper_cart_wrap{
         width: 640px;
         margin-right: 16px;
    }
}
 @media screen and (min-width: 1300px){
     .swiper_cart_wrap{
         margin-right: 68px;
    }
}
 .swiper__card, .swiper_trumb{
     height: 620px;
}
 @media screen and (max-width: 768px){
     .swiper__card{
      height: 328px;

    }
     .swiper_trumb{
         display: none;
    }

}
 .swiper__card .swiper-slide, .swiper__card .swiper-slide img, .swiper__card .swiper_trumb, .swiper__card .swiper_trumb img{
     width: 100%;
    /* max-width: 100%;
    */
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     -o-object-position: center;
     object-position: center;
}
 .swiper_cart img, .swiper_trumb img{
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     -o-object-position: center;
     object-position: center;
}
 .swiper_trumb .swiper-slide{
     cursor: pointer;
}
 .swiper_trumb .swiper-slide-thumb-active{
     border: 1px solid rgb(178, 140, 106);
}
 .cart_top{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: center;
     align-items: center;
     margin-bottom: 8px;
}
 .availability_wrapper{
     margin: 0px 0 0px 0;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     color: rgb(104, 149, 117);
     font-size: 15px;
     font-weight: 400;
     line-height: 120%;
}
 .availability_wrapper span{
     margin: 0 0 0 6px;
     color: rgb(104, 149, 117);
}
 .availability_wrapper .not_aviabl{
     color: #7C183E;
}

  .product_article{
    color: rgb(128, 139, 142);
    font-size: 15px;
    font-weight: 400;
    line-height: 120%;
  }

 .card__title{
     color: rgb(2, 46, 56);
     font-size: 26px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0%;
     text-decoration: none;
     display: block;
     margin: 0 0 8px 0;
}
 @media screen and (min-width: 768px){
  .card__title{
     font-size: 32px;
   }
 }
 .card__title:hover{
     opacity: 0.8;
}
 .price__curr{
     color: rgb(124, 24, 62);
     font-size: 32px;
     font-weight: 500;
     line-height: 120%;
}
 .price__current{
     color: rgb(2, 46, 56);
     font-size: 32px;
     font-weight: 500;
     line-height: 120%;
}
 .price__old{
     text-decoration: line-through;
     margin-right: 8px;
     color: rgb(128, 139, 142);
     font-size: 18px;
     font-weight: 400;
     line-height: 120%;
}
 .price__curr{
     color: rgb(198, 12, 12);
}
 .price_pr_wr{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     margin: 0 0 4px 0;
}
 .procent_sale{
     color: rgb(255, 255, 255);
     font-size: 14px;
     font-weight: 450;
     line-height: 18px;
     padding: 1px 8px;
     border-radius: 1px;
     background: rgb(124, 24, 62);
}
 .link_carts_wrap{
    margin-bottom: 16px;
    text-align: center;
  }
 @media screen and (min-width: 1024px){
   .link_carts_wrap{
       display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: row;
       flex-direction: row;
       -webkit-flex-wrap: nowrap;
       flex-wrap: nowrap;
       -webkit-justify-content: center;
       justify-content: center;
       -webkit-align-items: center;
       align-items: center;
       column-gap: 42px;
       margin-bottom: 32px;
  }
}
 .link_cart-el{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
     column-gap: 8px;
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 120%;
     text-decoration: none;
     margin-bottom: 20px;
}
@media screen and (min-width: 1024px){
   .link_cart-el{
       -webkit-justify-content: flex-start;
       justify-content: flex-start;
       margin-bottom: 0;
  }
}
 .cart_video-wrapper{
     display: none;
     text-align: center;
}
 @media screen and (min-width: 768px){
     .cart_video-wrapper{
         display: block;
         margin: 44px 0 24px 0;
    }
}
 .cart_video_elem{
     display: inline-block;
     vertical-align: middle;
     margin: 0 6px 12px 6px;
     width: 60px;
     height: 60px;
     border-radius: 50%;
     padding: 4px;
     overflow: hidden;
     position: relative;
     background-color: rgb(124, 24, 62);
}
 @media screen and (min-width: 1024px){
   .cart_video_elem{
     margin: 0 16px 12px 16px;
     width: 100px;
     height: 100px;
     padding: 8px;
   }
 }
 .cart_video_elem img, 
 .cart_video_elem video, 
 .cart_video_elem iframe {
     background-color: grey;
     display: block;
     width: 54px;
     height: 54px;
     -o-object-fit: cover;
     object-fit: cover;
     -o-object-position: center;
     object-position: center;
     border-radius: 50%;
     position: relative;
     z-index: 1;
}
 .cart_video_elem:before{
     content: '';
     position: absolute;
     left: 2px;
     top: 2px;
     height: calc(100% - 4px);
     width: calc(100% - 4px);
     border-radius: 50%;
     border: 3px solid #fff;
     z-index: 2;
}
 .video-block{
     width: 54px;
     height: 54px;
     border-radius: 50%;
     overflow: hidden;
}
@media screen and (min-width: 1024px){
  .cart_video_elem img, .cart_video_elem video, .cart_video_elem iframe {
     width: 84px;
     height: 84px;
   }
   .cart_video_elem:before{
     left: 4px;
     top: 4px;
     height: calc(100% - 8px);
     width: calc(100% - 8px);
   }
    .video-block{
     width: 84px;
     height: 84px;
     border-radius: 50%;
     overflow: hidden;
    }
}

 .video-block iframe {
     width: 177%;
     transform: translateX(-21.5%);
}
 .review_cart_section{
     padding: 60px 0 20px 0;
}
 .review_cart_block{
     margin: 0px 0 0 0 ;
     display: block;
}
 @media screen and (min-width: 768px){
   .review_cart_section{
     padding: 80px 0 40px 0;
    }
   .review_cart_block{
     margin: 0px 0 0 0 ;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row-reverse;
     flex-direction: row-reverse;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: flex-start;
     align-items: flex-start;
  }
}
 .review_cart{
     padding: 24px 0 24px 0;
     border-bottom: 1px solid rgb(232, 232, 232);
}
 .review__title{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 500;
     line-height: 23px;
     margin: 0px 0 8px 0 ;
}
 .review__t-elem{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: flex-start;
     align-items: flex-start;
     margin: 0 0 12px 0;
}
 .review__date{
     color: rgb(128, 139, 142);
     font-size: 15px;
     font-weight: 400;
     line-height: 19px;
}
 .review__img{
     margin: 0.75rem 0 0rem 0;
}

 .review__img img{
     width: 160px;
     height: 120px;
     -o-object-fit: cover;
     object-fit: cover;
     -o-object-position: center;
     object-position: center;
     margin-right: 0.25rem;
}
 @media screen and (max-width: 767px){
   .review__img img{
     width: 120px;
     height: 90px;
   }
 }
 .review__bottom-block{
}
 @media screen and (min-width: 768px){
}
 .review__text , .review__text p{
     padding: 0;
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 140%;
     margin-bottom: 12px;
}
 .review__text{
     margin-bottom: 12px;
}
 .review__text p:last-child{
     margin: 0;
}
 .review_cart_result-wrapper{
     padding: 24px;
     background: rgb(247, 249, 250);
     width: auto;
     max-width: 100%;
     margin-left: 0px;
     margin-top: 24px;
     display: inline-block;
}
 .review_cart_list{
     width: 100%;
}
 @media screen and (min-width: 768px){
   .review_cart_result-wrapper{
       padding: 24px;
       width: calc( 33% - 16px );
       margin-left: 16px;
       margin-top: 24px;
  }
   .review_cart_list{
       width: 67%;
  }
 }
 @media screen and (min-width: 1320px){
   .review_cart_result-wrapper{
       width: calc( 25% - 16px );
  }
 }
 .review_cart_empty{
     padding: 40px 0;
     width: 626px;
     max-width: 100%;
}
 @media screen and (min-width: 768px){
 .review_cart_empty{
     padding: 60px 0;
     width: 626px;
  }
 }
 .review_cart_list-title{
     color: rgb(2, 46, 56);
     font-size: 24px;
     font-weight: 450;
     line-height: 120%;
     letter-spacing: 0.16px;
     margin: 0 0 12px 0;
}
 .review_cart_result-block{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 16px;
     margin-bottom: 16px;
}
 .review_cart-number{
     color: rgb(2, 46, 56);
     font-size: 44px;
     font-weight: 450;
     line-height: 120%;
}
 @media screen and (max-width: 768px){
}
 .review_cart_btn-wr .btn_tr{
     width: 100%;
}
/*.review_cart_btn-wr{
     text-align: center;
     margin: 20px 0 16px 0;
}
 */
/*.faq_element-container{
     border-top: 1px solid rgb(184, 184, 184);
}
*/
 .faq_element-head{
     padding: 14px 40px 14px 0px;
     position: relative;
}
 .faq_element-question {
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 500;
     line-height: 120%;
}
 .faq_element {
     border-bottom: 1px solid rgb(239, 239, 239);
     cursor: pointer;
     position: relative;
}
 .faq_element-icon {
     position: absolute;
     right: 0px;
     top: 17px;
     cursor: pointer;
}
 .faq_element-answer p, .faq_element-answer {
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 120%;
}
 .faq_element-answer{
     padding: 2px 0 16px 0;
}
 .faq_element-answer p:last-child {
     margin: 0px;
}
 .faq_element-body {
     padding: 0px 0 0 0;
     height: 0;
     min-height: 0px;
     position: relative;
     z-index: -2;
     overflow: hidden;
     opacity: 0;
     transition: all 0.3s ease;
}
 .open .faq_element-body {
     height: auto;
     min-height: 30px ;
     z-index: 1;
     overflow: visible;
     opacity: 1;
}
 .open .faq_element-icon {
     -ms-transform: rotate(180deg);
     transform: rotate(180deg);
}
 .charact-line{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: stretch;
     align-items: stretch;
     font-size: 15px;
     font-weight: 400;
     line-height: 120%;
     margin: 12px 0 12px 0;
}
 .charact-label{
     color: rgb(128, 139, 142);
}
 .charact-line span{
     display: block;
     width: auto;
     -webkit-flex: 1 1 auto;
     flex: 1 1 auto;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-end;
     justify-content: flex-end;
     border-bottom: 1px dashed rgb(217, 217, 217);
     margin: 0 2px 4px 2px;
}
 .charact-value{
     color: rgb(2, 46, 56);
}
 .rec_bl{
     min-height: 130px;
     padding: 20px;
     background: #fff;
     position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    column-gap: 12px;
}
 @media screen and (max-width: 767px){
  .rec_bl .card__img {
      height: 90px;
      width: 90px;
      margin: 0;
    }
  .rec_bl  .card_title {
      font-size: 15px;
      margin: 0px 0 12px 0;
      min-height: inherit;
      text-align: left;
  }
  .rec_bl 
  .cart-price_block {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center;
}
}
 @media screen and (min-width: 768px){
   .recoment_wrapper{
       display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: row;
       flex-direction: row;
       -webkit-flex-wrap: nowrap;
       flex-wrap: nowrap;
       -webkit-justify-content: flex-start;
       justify-content: flex-start;
  }
   .rec_bl{
       width: 33.33%;
       margin: 0;
       display: block;
  }
}
 .card_custom{
     padding: 45px;
/*     height: 258px;*/
     display: block;
     text-decoration: none;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     text-align: center;
      border: 1px solid #efefef;
}
 @media screen and (max-width: 767px){
   .card_custom{
     padding: 32px  20px  20px  20px;
   }
 }
 .card_c-text{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-transform: uppercase;
     margin: 0 0 12px 0;
     text-align: center;
}
 .card_g-text{
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 21px;
     text-align: center;
     margin: 0 0 2px 0;
}
 .card_c-price-old{
     color: rgb(128, 139, 142);
     font-size: 22px;
     font-weight: 400;
     line-height: 120%;
     letter-spacing: 0%;
     margin: 0 0 4px 0;
     text-align: center;
     text-decoration: line-through;
}
 .card_c-price{
     color: rgb(124, 24, 62);
     font-size: 32px;
     font-weight: 450;
     line-height: 120%;
     text-align: center;
     margin: 0 0 12px 0;
}
 .card_g-price-pr{
     color: rgb(124, 24, 62);
     font-size: 16px;
     font-weight: 450;
     line-height: 120%;
     margin: 0 0 12px 0;
     text-align: center;
}
 .card_custom .btn_cart{
     width: 100%;
     background: rgb(124, 24, 62);
     border: 1px solid rgb(124, 24, 62);
}
 .card_custom .btn_cart:hover{
     border: 1px solid rgb(124, 24, 62);
     color: rgb(124, 24, 62);
     background: transparent;
}
 @media screen and (max-width: 767px){
   .card_c-text{
      margin: 0 0 8px 0;
   }
    .card_c-price-old{
     font-size: 16px;
   }
    .card_c-price{
     font-size: 20px;
     margin: 0 0 8px 0;
  }
  .card_g-price-pr{
    margin: 0 0 8px 0;
  }
  .card_custom .btn_cart{
    width: auto;
    display: inline-block;
    padding-left: 32px;
    padding-right: 32px;
  }
 }

 .rec_bl:first-child:after{
     background-image: url(../img/plus.svg);
}
 .rec_bl:nth-child(2):after{
     background-image: url(../img/equal.svg);
}
 .rec_bl:first-child:after, .rec_bl:nth-child(2):after{
     content: '';
     display: block;
     width: 52px;
     height: 52px;
     padding: 8px 8px;
     border-radius: 50%;
     position: absolute;
     border: 1px solid rgb(239, 239, 239);
     top: 50%;
     transform: translateY(-50%);
     right: -26px;
     left: inherit;
     bottom: inherit;
     text-align: center;
     background-color: rgb(255, 255, 255);
     background-repeat: no-repeat;
     background-position: center;
     background-size: auto;
     z-index: 1;
}

 @media screen and (max-width: 767px){
   .rec_bl:first-child:after, .rec_bl:nth-child(2):after{
     width: 40px;
     height: 40px;
     right: inherit;
     left: 50%;
     transform: translate(-20px, -20px);
     top: 100%;
   }
 }
 .form_review{
     padding: 24px 0px 0 0 ;
}
@media screen and (min-width: 560px) {
   .form_review{
     padding: 24px 8px;
  }
}
 @media screen and (min-width: 768px){
     .form_review{
         padding: 24px 8px;
    }
}
/* .form-fl_bl{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     column-gap: 8px;
}*/
@media screen and (min-width: 460px){
     .form-fl_bl{
       display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: row;
       flex-direction: row;
       -webkit-flex-wrap: nowrap;
       flex-wrap: nowrap;
       column-gap: 8px;
  }
}
 @media screen and (min-width: 768px){
     .form-fl_bl{
         column-gap: 16px;
    }
}
 .input_custom, .textarea_custom{
     border: 1px solid rgb(232, 232, 232);
     background: rgb(255, 255, 255);
     margin: 0 0 20px 0;
     padding: 14px 16px;
     color: rgb(128, 139, 142);
     font-size: 15px;
     font-weight: 400;
     height: 40px;
     width: 100%;
}
 .textarea_custom{
     height: 120px;
     line-height: 120%;
     display: block;
     resize: none;
     margin: 0 0 24px 0;
}
 .input_custom:focus, .textarea_custom:focus{
     outline: none;
}
 .form-fl_bl .input_custom, .form-fl_bl .textarea_custom{
     margin-bottom: 24px;
}
 .submit_custom, .submit_reset{
     box-sizing: border-box;
     height: 46px;
     padding: 0 10px;
     text-align: center;
     font-size: 14px;
     font-weight: 500;
     letter-spacing: 0.32px;
     text-transform: uppercase;
     width: 100%;
     margin: 0 0 20px 0;
     transition:all 0.2s ease;
}
 .submit_reset{
     background: rgb(243, 243, 246);
     border: 1px solid rgb(243, 243, 246);
     color: rgb(17, 17, 17);
}
 .submit_reset:hover{
     color: rgb(243, 243, 246);
     background:rgb(17, 17, 17);
}
 .submit_custom{
     background: rgb(2, 46, 56);
     border: 1px solid rgb(2, 46, 56);
     color: rgb(255, 255, 255);
}
 .submit_custom:hover{
     color: rgb(2, 46, 56);
     background: #fff;
}
 .form_contact .input_custom{
     margin-bottom: 24px;
}
 .form_contact .submit_custom{
     width: auto;
     padding-left: 32px;
     padding-right: 32px;
}
 .form_btn{
     text-align: center;
}
 .personal_wrap-sh{
       width: 100%;
  }
@media screen and (min-width: 768px){
   .personal_wrap-sh{
       width: 66.4%;
  }
}
 .personal_wrap-w{
     width: 100%;
}
 .personal_wrap .input_custom, .personal_wrap .textarea_custom{
     margin: 0 0 24px 0;
}
 .personal_wrap .form-fl_bl {
   width: 100%;
   -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     /*column-gap: 24px;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;*/
}
@media screen and (min-width: 768px){
   .personal_wrap .form-fl_bl {
     column-gap: 12px;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
  }
}
@media screen and (min-width: 768px){
   .personal_wrap .form-fl_bl {
     column-gap: 24px;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
  }
}
 .personal_wrap .form_btns{
     margin-top: 8px;
}
 .input_pass .input_custom{
     width: 100%;
}
 @media screen and (min-width: 560px){
     .personal_wrap-sh .input_custom{
         width: calc( 50% - 12px);
    }
     .personal_wrap-w .input_custom{
         width: calc( 33.33% - 6px);
    }
     .input_pass .input_custom{
         width: 100%;
    }
}
@media screen and (min-width: 768px){
     .personal_wrap-w .input_custom{
         width: calc( 33.33% - 12px);
    }
}



 .form_head{
     text-align: center;
     margin-bottom: 24px;
}
 .form-block-top{
     margin-bottom: 40px;
}
 .form_head_img{
     text-align: center;
     margin-bottom: 24px;
}
 .form_head_title{
     color: rgb(2, 46, 56);
     font-size: 28px;
     font-weight: 450;
     line-height: 120%;
     letter-spacing: 0.16px;
     margin-bottom: 12px;
     text-transform: uppercase;
}
 .form_head_text{
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 140%;
     margin-bottom: 24px;
}
 .input_file-wrap{
  text-align: center;
 }
 .input_file-el{
  text-align: left;
  margin-bottom: 16px;
 }
@media screen and (min-width: 560px) {
   .input_file-wrap{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: flex-start;
     align-items: flex-start;
     text-align: left;
  }
}
 .form_btns {
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     column-gap: 12px;
}
 .form-block{
     text-align: center;
}
 @media screen and (min-width: 768px){
     .form_btns button, .form-block .submit_custom{
         width: auto;
         padding-left: 50px;
         padding-right: 50px;
    }
}
 .pass_link_wrapper{
     text-align: right;
     margin-bottom: 20px;
}
 .pass_link_wrapper a{
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 16px;
     text-decoration: none;
}
 .pass_link_wrapper a:hover{
     text-decoration: none;
     opacity: 0.8;
}
 .input_file-wrap label{
}
 @media screen and (min-width: 768px){
}
 .input_file-wrap label span{
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 16px;
     padding-left: 32px;
     position: relative;
     display: block;
     margin: 12px 0;
}
 .input_file-wrap label span svg{
     position: absolute;
     left: 0;
     top: 0;
}
 #upload-photo {
     opacity: 0;
     position: absolute;
     z-index: -1;
}
 .input_file-wrap .submit_custom{
     width: auto;
     padding-left: 32px;
     padding-right: 32px;
}
 .result_photo{
     margin: 12px 0;
     max-width: 200px;
     overflow: hidden;
     text-overflow: ellipsis;
     padding-left: 32px;
     position: relative;
}
 .result_photo svg{
     position: absolute;
     left: 0;
     top: 0;
}
 .rating {
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row-reverse;
     flex-direction: row-reverse;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     margin-bottom: 24px;
}
 .rating:not(:checked) > input {
     display: none;
}
 .rating > input:checked ~ label, .rating:not(:checked) > label {
     width: 20px;
     height: 20px;
     cursor: pointer;
     background-size: 20px 20px;
     background-image: url(../img/star_f.svg);
     background-position: center;
     background-repeat: no-repeat;
     background-size: auto;
     margin: 0 2px 0 2px;
}
 .rating>input:checked~label {
     background-image: url(../img/star_a.svg);
     background-size: 20px 20px;
}
 .rating:not(:checked)>label:hover, .rating:not(:checked)>label:hover~label {
     background-image: url(../img/star_a.svg);
     background-size: 20px 20px;
}
 .rating>input:checked+label:hover, .rating>input:checked+label:hover~label, .rating>input:checked~label:hover, .rating>input:checked~label:hover~label, .rating>label:hover~input:checked~label {
     background-image: url(../img/star_a.svg);
     background-size: 20px 20px;
}
 .form_success{
     text-align: center;
     padding: 40px 40px;
     height: calc(100% - 100px);
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-content: center;
     align-content: center;
}
 .form-img{
     margin: 0 0 30px 0;
}
 .form-title{
     color: rgb(17, 17, 17);
     font-family: Unbounded;
     font-size: 18px;
     font-weight: 600;
     line-height: 140%;
     text-transform: uppercase;
     margin: 0 0 12px 0;
}
 .form_success .form-text_acc{
     margin: 0 0 24px 0;
}
 .formtitle_text{
     color: rgb(17, 17, 17);
     font-size: 14px;
     font-weight: 400;
     line-height: 140%;
     margin: 20px 0;
     text-align: center;
}
 .input_pass-wrap{
     position: relative;
}
 .input_pass-wrap i{
     position: absolute;
     top: 14px;
     right: 16px;
     display: block;
     width: 20px;
     height: 14px;
     background-repeat: no-repeat;
     background-color: transparent;
     background-position: center;
     background-image: url(../img/eye.svg);
     cursor: pointer;
}
 .form-fl_bl .input_pass-wrap{
     margin-top: 0px;
     width: 100%;
}
@media screen and (min-width: 560px){
 .form-fl_bl .input_pass-wrap{
     width: calc( 50% - 12px);
  }
}
@media screen and (min-width: 1024px){
 .form-fl_bl .input_pass-wrap{
     width: calc( 50% - 12px);
     margin-top: 12px;
 }
}
 .blog-elems_wrapper{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     column-gap: 48px;
     padding: 0px 0;
     margin-bottom: 54px;
}
 .blog-elem{
     width: 100%;
     margin-bottom: 16px;
}

 @media screen and (min-width: 1024px){
     .blog-elem{
         width: calc(50% - 24px);
    }
    .blog-elem:nth-child(2n+2){
     margin-top: 48px;
  }
}
 .blog-elem_body{
}
 .blog-elem_img{
     height: 200px;
     display: block;
     margin-bottom: 16px;
}
@media screen and (min-width: 1024px){
   .blog-elem_img{
     height: 365px;
     margin-bottom: 24px;
  }
}
 .blog-elem_img img{
     height: 100%;
     width: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     -o-object-position: center;
     object-position: center;
}
 .blog-elem-top{
     color: rgb(128, 139, 142);
     font-size: 14px;
     font-weight: 400;
     line-height: 120%;
     margin: 0 0 12px 0;
}
 .blog-elem_title{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 450;
     line-height: 120%;
     letter-spacing: 0.16px;
     margin: 0 0 16px 0;
     text-decoration: none;
     display: block;
}
@media screen and (min-width: 1024px){
   .blog-elem_title{
     font-size: 22px;
   }
}
 .blog-elem_title:hover{
     color: rgb(43, 43, 43);
     opacity: .8;
}
 @media screen and (min-width: 768px) {
   .article__page{
       margin-right: 8.5%;
       margin-left: 8.5%;
  }
}
 .article__page .title_main{
     margin-bottom: 16px;
}
 .article_date{
     color: rgb(128, 139, 142);
     font-size: 16px;
     font-weight: 400;
     line-height: 120%;
     text-align: center;
     margin-bottom: 16px;
}
 .article__title-img{
   margin-right: 0%;
   margin-left: 0%;
   height: 250px;
  }
@media screen and (min-width: 768px) {
   .article__title-img{
       margin-right: 10.2%;
       margin-left: 10.2%;
       height: 500px;
  }
}
 .article__title-img img{
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     -o-object-position: center;
     object-position: center;
}
 .article_text-wrapper{
     width: 740px;
     max-width: 100%;
     margin: 40px auto 40px auto;
}
 .simple_text-wrapper{
     margin: 0 0 80px 0;
}
 .article_text p, .article_text{
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 140%;
}
 @media screen and (min-width: 768px){
     .article_text p, .article_text{
         font-size: 18px;
    }
}
 .article_text p{
     margin: 0 0 1rem 0;
}
 .article_text ul, .simple__page-block ul{
     display: block;
     list-style: none;
     margin: 0;
     padding: 0;
}
 .section_simple ul{
     margin: 0 0 16px 0;
}
 .article_text ul li, .simple__page-block ul li{
     margin: 8px 0 8px 0;
     padding-left: 28px;
     position: relative;
}
 .article_text ul li:before, .simple__page-block ul li:before{
     content: '';
     width: 16px;
     height: 16px;
     background: rgb(178, 140, 106);
     display: block;
     position: absolute;
     border-radius: 50%;
     left: 0;
     top: 5px;
     border: 4px solid rgb(253, 241, 232);
}
 .article__images{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: stretch;
     align-items: stretch;
     column-gap: 16px;
}
 .article__images img{
     width: calc( 33.33% - 1%);
     height: 280px;
     -o-object-fit: cover;
     object-fit: cover;
     -o-object-position: center;
     object-position: center;
}
 .text_citat-block{
     margin: 40px 0;
     padding: 24px;
     position: relative;
     color: rgb(178, 140, 106);
     font-size: 20px;
     font-weight: 400;
     line-height: 120%;
     text-align: center;
     border-top: 1px solid rgb(178, 140, 106);
     border-bottom: 1px solid rgb(178, 140, 106);
}
 @media screen and (min-width: 768px){
}
 .text_citat-block svg{
     position: absolute;
     top: 0;
     left: 50%;
     padding: 4px;
     width: 38px;
     height: 32px;
     background: #fff;
     transform: translate(-50% , -50%);
}
 .simple_marg{
     margin-top: -12px;
}
 .section__simple .simple__page-wrapper, .section-simple .simple__page-wrapper{
     min-height: 50vh;
}
@media screen and (min-width: 768px){
 .section_simple .title_main{
       margin-bottom: 40px;
  }
}
 .simple__title-block{
     padding-top: 60px;
     margin-bottom: 24px;
}
 @media screen and (min-width: 768px){
}
 @media screen and (min-width: 768px){
     .simple__page-wrapper {
         display: -webkit-flex;
         display: flex;
         -webkit-flex-direction: row-reverse;
         flex-direction: row-reverse;
         -webkit-flex-wrap: nowrap;
         flex-wrap: nowrap;
         -webkit-justify-content: flex-start;
         justify-content: flex-start;
         -webkit-align-content: stretch;
         align-content: stretch;
         -webkit-align-items: stretch;
         align-items: stretch;
    }
}
 .simple__page-menu{
     padding: 40px 0 60px 0;
}
 @media screen and (min-width: 768px){
   .simple__page-menu{
     padding: 20px 0 0 0;
    }
     .simple__page-menu{
         width: 30%;
         padding: 0 8px 0 0;
    }
     .simple__page-block{
         width: 70%;
         padding-left: 8px;
         margin-bottom: 40px;
    }
}
 @media screen and (min-width: 1024px){
     .simple__page-menu{
         width: 25%;
         padding: 0 8px 0 0;
    }
     .simple__page-block{
         width: 75%;
         padding-left: 8px;
         margin-bottom: 40px;
    }
     .section-simple .simple__page-block{
         padding-bottom: 40px;
    }
}
 .simple__img{
       height: 160px;
       margin: 0  0 16px 0;
  }
 @media screen and (min-width: 768px){
   .simple__img{
       height: 280px;
       margin: 0 0 32px 0;
  }
}
 .simple__img img{
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     -o-object-position: center;
     object-position: center;
}
 .delive_block-text a{
     color: rgb(178, 140, 106);
}
.about-block_icons{
  text-align: center;
  margin-bottom: 32px;
}
@media screen and (min-width: 768px){
   .about-block_icons{
       display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: row;
       flex-direction: row;
       -webkit-flex-wrap: nowrap;
       flex-wrap: nowrap;
       -webkit-justify-content: space-between;
       justify-content: space-between;
       column-gap: 32px;
       margin-bottom: 40px;
  }
}
 .about-bl{
     text-align: center;
     width: 170px;
     display: inline-block;
     vertical-align: top;
     margin-bottom: 16px;
}
@media screen and (min-width: 768px){
  .about-bl{
     width: 20%;
     margin: 0;
  }
}
 .about-bl-img{
     width: 70px;
     height: 70px;
     border-radius: 50%;
     background: rgb(244, 247, 249);
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     margin: 0 auto 20px auto;
}
 .about-bl-text{
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 500;
     line-height: 120%;
     text-align: center;
     padding: 0 4px;
}
 .simple_text{
     margin: 0 0 40px 0;
}
 .simple_text p{
     line-height: 140%;
}
 .simple-img{
     height: 340px;
     margin: 32px 0;
}
 .simple-img img{
     width: 100%;
     height: 100%;
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     -o-object-position: center;
     object-position: center;
}
 .menu_fon{
     background: rgba(247, 249, 250, 0.5);
     position: relative;
     height: 100%;
     margin-right: 16px;
}
@media screen and (min-width: 1024px) {
 .menu_fon{
     margin-right: 44px;
  }
}
 .menu_fon:before, .menu_fon:after{
     content: '';
     display: block;
     width: 500px;
     height: 100%;
     position: absolute;
     top: 0;
     background: rgba(247, 249, 250, 0.5);
}
 .menu_fon:before{
     right: 100%;
}
 .menu_fon:after{
     left: 100%;
}
 @media screen and (min-width: 768px){
     .menu_fon:after{
         display: none;
    }
}
 .aside_list{
     list-style: none;
     margin: 0;
     padding: 0;
}
 .aside_list li {
     margin: 0 0 24px 0;
}
 .aside_list li a{
     color: rgb(128, 139, 142);
     font-size: 18px;
     font-weight: 500;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-transform: uppercase;
     position: relative;
     text-decoration: none;
}
 .aside_list li.current a, .aside_list li a:hover{
     color: rgb(2, 46, 56);
     text-decoration: none;
}
 .aside__list{
     list-style: none;
     margin: 0px 0 0 0;
     padding: 20px 0 20px 0;
     overflow: hidden;
}
 @media screen and (min-width: 768px){
     .aside__list{
         padding: 60px 0 0 0;
         margin: 0;
    }
}
 .aside__list li {
     margin: 0 0 24px 0;
}
 .aside__list li a{
     color: rgb(128, 139, 142);
     font-size: 18px;
     font-weight: 500;
     line-height: 120%;
     text-transform: uppercase;
     position: relative;
     text-decoration: none;
     transition: all 0.3s ease;
}
 @media screen and (min-width: 768px) and (max-width: 1023px){
    .aside__list li a{
     font-size: 16px;
   }
 }
 .aside__list li.active a, .aside__list li a:hover{
     color: rgb(2, 46, 56);
     text-decoration: none;
}
 .delive_text-wrapper{
}
 .delive_block_wrap{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     margin: 0;
     position: relative;
     padding-left: 64px;
}
 @media screen and (min-width: 768px){
     .delive_block_wrap{
         padding-left: 94px;
    }
}
 .delive_block-icon{
     background: rgb(244, 247, 249);
    ;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     padding: 6px;
     margin: 0 24px 0 0;
     position: absolute;
     left: 0;
     top: 4px;
     width: 44px;
     height: 44px;
     border-radius: 50%;
}
 @media screen and (min-width: 768px){
     .delive_block-icon{
         width: 70px;
         height: 70px;
    }
}
 .delive_block-icon svg{
     max-width: 100%;
     max-height: 100%;
}
 .delive_title{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 450;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-transform: uppercase;
     margin: 0 0 12px 0;
}
@media screen and (min-width: 768px){
    .delive_title{
     font-size: 24px;
   }
}
 .title_bl-bold, .title_ind{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 500;
     line-height: 140%;
     margin: 0 0 12px 0;
}
 .title_ind{
     text-transform: uppercase;
}
 .delive_block-text{
     margin-bottom: 24px;
}
 .delive_block-text, .delive_block-text p{
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 400;
     line-height: 140%;
}
@media screen and (min-width: 768px){
   .delive_block-text{
       margin-bottom: 40px;
  }
   .delive_block-text, .delive_block-text p{
       font-size: 16px;
  }
}
 .delive_block-text p{
     margin-bottom: 16px;
}
 .delive_block-text p:last-child{
     margin-bottom: 0;
}
 .delive_block-text strong{
     font-weight: bold;
}
 .delive_font_s{
     font-size: 18px;
}
 .ind_wrapper{
     margin-bottom: 32px;
}

.ind_img{
     height: 120px;
     width: 100%;
     margin-bottom: 16px;
     flex: 1 0 auto;
}
@media screen and (min-width: 460px){
 .ind_img{
     height: 100px;
     margin: 0;
     width: 160px;
   }
  .ind_block{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: stretch;
     align-items: stretch;
     column-gap: 16px;
}
}
@media screen and (min-width: 768px){
 .ind_img{
     height: 120px;
     width: 200px;
   }
    .ind_block{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: stretch;
     align-items: stretch;
     column-gap: 40px;
}
}
@media screen and (min-width: 1024px){
 .ind_img{
     height: 260px;
     width: 408px;
 }
}
 .ind_img img{
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     display: block;
     -o-object-position: center;
     object-position: center;
}
.contact__wrapper{
     padding: 16px;
}
@media screen and (min-width: 768px){
   .contact__wrapper{
       padding: 40px;
  }
}
 .contact__elements{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
}
 .contact__elem{
     width: 100%;
     max-width: 100%;
     margin: 0 0 24px 0;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 12px;
}
 @media screen and (min-width: 560px){
     .contact__elem{
         width: 50%;
         margin: 0 0 40px 0;
    }
}
 @media screen and (min-width: 1024px){
     .contact__elem{
         width: 33.33%;
    }
}


 .contact__elem-img{
     width: 60px;
     height: 60px;
     background: rgb(244, 247, 249);
     border-radius: 50%;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
}
 .contact__title{
     color: rgb(128, 139, 142);
     font-size: 15px;
     font-weight: 400;
     line-height: 120%;
     margin: 0 0 6px 0;
}
 .contact__elem span, .contact__elem a{
     display: block;
     text-decoration: none;
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 120%;
}
 .contact__elem a{
     margin: 0 0 0px 0;
}
 .contact__elem a:hover{
     text-decoration: none;
     color: rgb(2, 46, 56);
     opacity: 0.8;
}
 .contact-m__title{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 450;
     line-height: 120%;
     letter-spacing: 0.16px;
     margin: 0 0 16px 0;
}
 .map_wrapper{
     margin-bottom: 80px;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     column-gap: 16px;
}


 .map_title{
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 450;
     line-height: 19px;
     text-transform: uppercase;
     margin: 0 0 8px 0;
}
 .map_text{
     padding-bottom: 28px;
}
 .map_block{
     width: 100%;
}
@media screen and (min-width: 460px){
   .map_block{
     width: 48%;
  }
}
 @media screen and (min-width: 768px){
     .map_wrapper{
         column-gap: 32px;
    }
     .map_block{
         width: 23%;
    }
}
 .map_text a, .map_text span, .map_text p{
     margin: 0 0 4px 0;
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 400;
     line-height: 18px;
     text-decoration: none;
     display: block;
}
 .map_text a:hover{
     opacity: 0.8s;
}
 .adress_block-wrapper{
     margin: 0 0 40px 0;
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 19px;
     position: relative;
     width: 100%;
     padding: 16px 10px;
     border: 1px solid rgb(232, 232, 232);
}
 @media screen and (min-width: 560px){
     .adress_block-wrapper{
         width: 484px;
         padding: 20px 24px;
    }
}
 .icon_adress{
     background: rgb(244, 247, 249);
     height: 70px;
     width: 70px;
     position: absolute;
     right: 24px;
     top: 20px;
     text-align: center;
     padding: 8px ;
     border-radius: 50%;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
}
 .adress_block-wrapper span{
     display: block;
     margin-bottom: 4px;
}
 .adress_block-btns{
     margin-top: 16px;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: center;
     align-items: center;
}
 .adress_btn{
     color: rgb(128, 139, 142);
     font-size: 14px;
     font-weight: 500;
     line-height: 18px;
     letter-spacing: 0.32px;
     text-transform: uppercase;
     background: transparent;
     border: none;
     position: relative;
     padding: 0 0 0 24px;
     margin: 0 26px 0 0;
}
 .adress_btn:last-child{
     margin: 0;
}
 .adress_btn svg{
     position: absolute;
     left: 0;
     top: 1px;
}
 .history_wrap{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     text-align: center;
     min-height: 50vh;
     padding-right: 8px;
     padding-left: 8px;
}
 @media screen and (min-width: 768px){
   .history_wrap{
      min-height: 100%;
    padding-right: 54px;
     padding-left: 8px;
   }
 }

 @media screen and (min-width: 1200px){

 .history_wraper{
     padding-right: 50px;
  }
 }
 .history_element{
     margin: 0 0 0px 0;
     width: 100%;
}
 .history_head, .history_block, .history_order{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
    -webkit-align-items: flex-start;
    align-items: flex-start;
     position: relative;
     width: 100%;
}
 .history_order{
     height: 0;
     opacity: 0;
     visibility: hidden;
     min-height: 0;
     transition: min-height 0.2s linear;
     border-bottom: 1px solid transparent;
}
 .open .history_order{
     height: auto;
     opacity: 1;
     visibility: visible;
     min-height: 200px;
     border-bottom: 1px solid rgb(239, 239, 239);
}
 .history_row{
     border-bottom: 1px solid rgb(239, 239, 239);
}
 .history_head{
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 500;
     line-height: 18px;
     text-transform: uppercase;
     padding-bottom: 8px;
     display: none;
}
 .history_td{
     padding: 4px 8px;
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 19px;
     text-align: left;
     width: 50%;
}
 .history_td:first-child{
     padding-left: 0;
/*     width: 28%;*/
}
 .history_td:nth-child(3), .history_td:nth-child(4){
     text-align: left;
}
 .history_td:nth-child(5){
     text-align: right;
     width: 100%;
}
 .history_date, .history_status, .history_summ, .history_order-n{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: center;
     justify-content: center;
}
 .status_ok{
     color: #689575;
}
 .status_del{
     color: #7C183E;
}
 .history_order-n{
     color: #B28C6A;
     text-align: right;
}
 .history_order-n-ins{
     position: relative;
     padding-right: 24px;
     min-height: 24px;
}
 .history_summ{
     text-align: center;
}
 .history_arrow{
     position: absolute;
     right: 0px;
     top: 0px;
     cursor: pointer;
}
 .open .history_arrow{
     transform: rotate(180deg);
}
 @media screen and (min-width: 768px) {
   .history_head{
      display: -webkit-flex;
     display: flex;
   }
  .history_head, 
  .history_block, 
  .history_order{
     -webkit-align-items: stretch;
     align-items: stretch;
  }
   .history_td{
       text-align: left;
       width: auto;
  }
   .history_td:first-child{
       padding-left: 0;
       width: 28%;
  }
   .history_td:nth-child(3), .history_td:nth-child(4){
       text-align: center;
  }
   .history_td:nth-child(5){
       text-align: right;
       width: auto;
  }
}

.mob_vis{
  color: rgb(2, 46, 56);
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  margin: 0 0 6px 0 ;
  text-transform: uppercase;
}
 @media screen and (min-width: 768px) {
     .mob_vis{
         display: none;
  }
}
 .status_images{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-content: center;
     align-content: center;
     -webkit-align-items: stretch;
     align-items: stretch;
     column-gap: 12px;
}
 .status_img{
     width: 40px;
     height: 40px;
     position: relative;
     margin: 0px 0;
}
@media screen and (min-width: 768px) {
 .status_img{
     width: 36px;
     height: 36px;
  }
}
@media screen and (min-width: 1200px) {
 .status_img{
     width: 60px;
     height: 60px;
  }
}
 .status_img img{
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     display: block;
}
 .history_info{
     padding: 24px 0px 24px 0px;
     width: 100%;
     border-bottom: 1px solid rgb(239, 239, 239);
}
 .history_info-order{
     width: 100%;
     text-align: left;
     padding: 16px 0;
}
 .history_info-line{
     color: rgb(128, 139, 142);
     font-size: 15px;
     font-weight: 400;
     line-height: 19px;
     margin: 0 0 8px 0;
     text-align: left;
}
 .history_info-line span{
     color: rgb(2, 46, 56);
     padding-left: 8px;
}
 .info-order-row{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: stretch;
     align-items: stretch;
     width: 100%;
}
 .info-order-img{
     width: 70px;
     height: 70px;
     margin-bottom: 6px;
}
@media screen and (min-width: 768px) {
  .info-order-img{
    margin: 0;
  }
}
@media screen and (min-width: 1024px) {
   .info-order-img{
    width: 100px;
    height: 100px;
    margin: 0;
  }
   .history_info-order{
     padding: 0px 0;
  }

}
@media screen and (min-width: 768px) {
   .info-order-row{
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
  }
}

 .info-order-img img{
     width: 100%;
     height: 100%;
     -o-object-fit: cover;
     object-fit: cover;
     display: block;
}
 .info-order-td{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: center;
     justify-content: center;
     padding: 0 6px;
     flex: 1 0 auto;
     width: 50%;
}

 .info-order-td:first-child{
     padding-left: 0;
     width: 70px;
     flex: 0 0 auto;
}
@media screen and (min-width: 1024px) {
  .info-order-td:first-child{
    width: 100px;
  }

}
 .info-order-td:nth-child(2){
     width: 40%;
}
 .info-order-td:last-child{
     text-align: right;
}

@media screen and (min-width: 768px) {
 .info-order-td{
    width: auto;
  }
  .info-order-td:nth-child(2){
       width: 40%;
  }
   .info-order-td:last-child{
       text-align: right;
  }
}
 .info-order-title{
     color: rgb(2, 46, 56);
     font-size: 15px;
     font-weight: 400;
     line-height: 19px;
}
 .info-order-descr{
     color: rgb(128, 139, 142);
     font-size: 15px;
     font-weight: 400;
     line-height: 120%;
}
 .info-order-price-old{
     color: rgb(128, 139, 142);
     font-size: 14px;
     font-weight: 400;
     line-height: 18px;
     text-decoration: line-through;
}
 .info-order-price-new{
     color: rgb(124, 24, 62);
     font-size: 18px;
     font-weight: 450;
     line-height: 23px;
}
 .info-order-price--curr{
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 500;
     line-height: 23px;
}
 .order-detals-wrap{
     border: 1px solid rgb(239, 239, 239);
     margin-top: -1px;
     padding: 16px 10px;
}
 @media screen and (min-width: 460px) {
   .order-detals-wrap{
     padding: 20px 16px;
  }
 }
@media screen and (min-width: 1024px) {
 .order-detals-wrap{
     border: 1px solid rgb(239, 239, 239);
     margin-top: -1px;
     padding: 24px 32px;
  }
}
 .order-total-wrap{
     border-bottom: 1px solid rgb(239, 239, 239);
     padding: 16px 0px;
}
 .order-total-line{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-content: center;
     align-content: center;
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 21px;
     margin: 0 0 12px 0;
}
 .total-sale{
     color: rgb(124, 24, 62);
}
 .total-line_b{
     color: rgb(17, 17, 17);
     font-size: 16px;
     font-weight: 600;
}
 .total_b{
     color: rgb(2, 46, 56);
     font-size: 20px;
     font-weight: 500;
     line-height: 26px;
}
 .total-summ{
     color: rgb(2, 46, 56);
     font-size: 22px;
     font-weight: 600;
     line-height: 28px;
}
 .btn_total-pos{
     text-align: right;
}
 .btn_total-pos .submit_custom{
     width: auto;
     padding-left: 24px;
     padding-right: 24px;
}
 @media screen and (min-width: 1024px) {
     .order_wrapper{
         display: -webkit-flex;
         display: flex;
         -webkit-flex-direction: row;
         flex-direction: row;
         -webkit-flex-wrap: nowrap;
         flex-wrap: nowrap;
         -webkit-justify-content: space-between;
         justify-content: space-between;
         -webkit-align-items: stretch;
         align-items: stretch;
         column-gap: 16px;
         padding-top: 60px;
    }
}
 @media screen and (min-width: 1024px) {
     .order_list-block{
         width: 50%;
         padding-right: 8px;
    }
     .order_block{
         width: 50%;
         padding-left: 8px;
    }
}
 @media screen and (min-width: 1200px) {
     .order_list-block{
         width: 50%;
         padding-right: 0;
    }
     .order_block{
         width: 41.6%;
         padding-left: 28px;
    }
}
 .delivery_free-block{
     padding: 16px 20px;
     background: rgb(239, 239, 239);
     margin: 0 0 20px 0;
}
 .delivery_free-wrap-title{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 20px;
     color: rgb(17, 17, 17);
     font-size: 11px;
     font-weight: 600;
     line-height: 120%;
     text-transform: uppercase;
}
 @media screen and (min-width: 1024px) {
     .delivery_free-wrap-title{
         font-size: 14px;
    }
}
 .delivery_free-title{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 12px;
}
 .delivery_free-title svg, .delivery_free-title span{
     display: inline-block;
     vertical-align: middle;
}
 .delivery_free-title svg{
     flex: 1 0 auto ;
}
 .delivery_free-summ{
     white-space: nowrap;
}
 .delivery_free-indicator{
     width: 100%;
     height: 7px;
     position: relative;
     background: rgb(210, 210, 210);
     margin: 14px 0 0 0 ;
}
 .delivery_free-indicator span{
     position: absolute;
     left: 0;
     height: 100%;
     display: block;
     z-index: 3;
     background: rgb(131, 146, 131);
}
 .conditions_wrapper{
     margin: 24px 0 0 0;
}
 .conditions_line{
     color: rgb(17, 17, 17);
     font-size: 14px;
     font-weight: 400;
     line-height: 140%;
     margin: 0 0 8px 0;
     padding-left: 24px;
     position: relative;
}
 .conditions_line:after, .conditions_line:before{
     content: '';
     position: absolute;
     display: block;
}
 .conditions_line:after{
     width: 12px;
     height: 12px;
     border-radius: 50%;
     background: rgb(131, 146, 131);
     left: 0;
     top: 3px;
}
 .conditions_line:before{
     left: 2px;
     top: 5px;
     width: 8px;
     height: 5px;
     border-style: solid;
     border-width: 2px 2px 0 0;
     border-color: #fff #fff transparent transparent;
     -ms-transform: rotate(135deg);
     transform: rotate(135deg);
     z-index: 2;
}
 .order_step-title{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     color: rgb(2, 46, 56);
     font-size: 18px;
     font-weight: 450;
     line-height: 120%;
     letter-spacing: 0.16px;
     text-transform: uppercase;
     margin: 0px 0 26px 0;
     column-gap: 12px;
}
 @media screen and (min-width: 768px) {
     .order_step-title{
         font-size: 24px;
         margin: 0px 0 26px 0;
    }
}
 .step-number{
     width: 32px;
     height: 32px;
     color: rgb(255, 255, 255);
     background: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 450;
     line-height: 32px;
     text-align: center;
     text-transform: uppercase;
     border-radius: 50%;
}
 .tabContent{
     min-height: 180px;
}
 .activ.tabContent {
     width: 100%;
     opacity: 1;
     height: auto;
     -webkit-transition: opacity 0.2s ease;
     -moz-transition: opacity 0.2s ease;
     -o-transition: opacity 0.2s ease;
     transition: opacity 0.2s ease;
}
 .hide {
/*     display: none !important;*/
     opacity: 0;
     z-index: -1;
     height: 0;
     overflow: hidden;
}
 .order_form-elem .input_custom{
     margin-bottom: 24px;
}
 .order_step-block{
     margin: 0 0 24px 0;
}
 @media screen and (min-width: 768px) {
     .order_step-block{
         margin: 0 0 38px 0;
    }
     .order_step-block:last-child{
         margin: 0 0 24px 0;
    }
}
 @media screen and (max-width: 1024px) {
     .order_step-block:last-child{
         margin: 0 0 24px 0;
    }
}
 .delivery_ch-block{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: stretch;
     align-items: stretch;
     column-gap: 13px;
     margin: 0 0 24px 0 ;
}
 .delivery_ch-el{
     display: block;
     width: calc(50% - 10px);
     margin: 0 0 8px 0;
     text-align: center;
}
 @media screen and (min-width: 768px) {
     .delivery_ch-el{
         display: block;
         width: calc(25% - 13px);
         margin: 0;
    }
}
 .delivery_content{
     background: rgb(248, 248, 248);
     border: 1px solid rgb(248, 248, 248);
     padding: 12px 12px;
     color: rgb(2, 46, 56);
     font-size: 13px;
     font-weight: 500;
     line-height: 16px;
     display: block;
     position: relative;
     cursor: pointer;
     min-height: 94px;
}
 .delivery_text{
     display: block;
}
 .delivery_text span{
     display: block;
     font-weight: 400;
}
 @media screen and (min-width: 1024px){
     .delivery_content{
         font-size: 15px;
         padding: 13px 12px 13px 12px;
    }
}
 .delivery_ch-el input[type='radio'] {
     position: absolute;
     opacity: 0;
     cursor: pointer;
}
 .delivery_ch-el input[type='radio']:checked + .delivery_content{
     border: 1px solid rgb(232, 232, 232);
     background: #fff;
}
 .delivery_ch-el svg{
     margin: 0 0 6px 0;
     display: inline-block;
}
 .order_form-elem .select_form{
     width: 100%;
}
 .accept_text{
     margin:0;
     text-align: center;
}
 .accept_text, .accept_text a{
     text-decoration: none;
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 400;
     line-height: 120%;
}
 .accept_text a:hover{
     text-decoration: none;
     opacity: 0.8;
}
 .promo-block-title{
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 450;
     line-height: 21px;
     margin-bottom: 10px;
}
 .promo_input-wrap{
     padding: 0;
     padding-bottom: 0px;
     border-bottom: 1px solid rgb(239, 239, 239);
}
@media screen and (min-width: 500px) {
   .promo_input-wrap{
     padding: 0;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     column-gap: 12px;
   }
}
 .promo_input-wrap .input_custom, .promo_input-wrap .textarea_custom{
     margin-bottom: 16px;
}
/*.promo_input{
     flex: 1 0 auto;
}
*/
 .promo_submit{
     color: rgb(2, 46, 56);
     font-size: 14px;
     font-weight: 500;
     line-height: 18px;
     letter-spacing: 0.32px;
     text-align: center;
     text-transform: uppercase;
     background: #fff;
     padding: 0 32px;
     height: 40px;
     border: 1px solid rgb(2, 46, 56);
     transition: all 0.2s linear;
     margin-bottom: 12px; width: 100%;
}
@media screen and (min-width: 500px) {
 .promo_submit{
  width: auto;
  margin-bottom: 0;
 }
}
 .promo_submit:hover{
     background: rgb(2, 46, 56);
     color: #fff;
}
 .comment-block{
     padding: 16px 0px 0px 0;
     color: rgb(2, 46, 56);
     font-size: 16px;
     font-weight: 400;
     line-height: 120%;
}
 .comment-block_head{
     position: relative;
     cursor: pointer;
}
 .comment-block-title{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 12px;
     padding-bottom: 16px;
}
 .comment-block-title{
     text-decoration: underline;
}
 .comment-block_body{
     height: 0;
     opacity: 0;
     visibility: hidden;
     transition: all 0.2s linear;
}
 .open .comment-block_body{
     height: auto;
     visibility: visible;
     opacity: 1;
}
 .open .comment-block-title{
     text-decoration: none;
}
 .social_elems{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
     flex-direction: row;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
    -webkit-justify-content: center;
    justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     column-gap: 12px;
}
@media screen and (min-width: 768px) {
   .social_elems{
     -webkit-justify-content: flex-start;
     justify-content: flex-start;

  }
}
 .social_el{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-flex-wrap: nowrap;
     flex-wrap: nowrap;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
     background: rgb(2, 46, 56);
     width: 44px;
     height: 44px;
     border-radius: 50%;
     text-decoration: none;
     margin: 0 0 12px 0;
     padding: 10px;

}
.social_el:hover{
     text-decoration: none;
}
 .soc_el{
     margin: 16px 16px 16px 0 ;
}
 .social_el svg, .social_el svg path{
     transition: all 0.3s ease;
}
 .social_el:hover svg, .social_el:hover svg path{
     fill: #839283;
}
.panel_modal-wrap{
  display: none;
}
.panel_menu-wrap{
  display: none;
}
@media screen and (max-width: 1024px) {
  .panel_modal-wrap{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 200;
    display: block;
  }
  .panel_modal{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    height: 60px;
    background: #fff;
    box-shadow: 0px -4px 6px 0px rgba(15, 25, 40, 0.04);
    position: relative;
    z-index: 200;
  }
  .panel_el{
    width: 20%;
    text-align: center;
    text-decoration: none;
    color: rgb(85, 92, 101);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
  .panel_el:nth-child(3){
    color: rgb(178, 140, 106);
    position: relative;
  }
  
  .panel_el span{
    display: block;
  }
  .panel_el:nth-child(3) svg{
    border: 1px solid rgb(229, 229, 229);
    border-radius: 50%;
    top: -28px;
    left: -11px;
    width: 42px;
    height: 42px;
    padding: 10px;
    background: #fff;
    position: absolute;
     z-index: 190;
  }
  .panel_el i{
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    position: relative;
  }
  .panel_el:nth-child(3) i:before{
    content: '';
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px -4px 6px 0px rgba(15, 25, 40, 0.04);
    position: absolute;
    top: -32px;
    left: -14px;
    z-index: 190;
  }
  .panel_menu-wrap{
/*    padding: 16px 16px 80px 16px;*/
    background: transparent;
    overflow-y: auto;
    max-height: 100vh;
    position: absolute;
    left: 0;
    z-index: 190;
    bottom: -96px;
    width: 100%;
    transition: all .2s ease 0s;
    display: block;
  }
  .menu_open.panel_menu-wrap{
    padding: 16px 16px 80px 16px;
    background: #fff;
    bottom: 0;
  }
  .panel_menu{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: stretch;
    align-items: stretch;
    margin-right: -4px;
    margin-left: -4px ;
    height: 0;
    transition: all .2s ease 0s;
    position: relative;
    bottom: -1000px;
  }
  .menu_open
  .panel_menu{
    height: inherit;
    bottom: 0;
  }
  .panel_menu-el{
    width: 33.33%;
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 8px;
  }
  .panel_menu-title{
    color: rgb(2, 46, 56);
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    margin: 8px 0 8px 0;
    min-height: 32px;
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
  }
  .panel_menu-img{
    width: 100%;
    height: 104px;
    background: rgb(248, 248, 248);
    padding: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .panel_menu-img img {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
}